.top-headline{
    width: 100%;
    padding-bottom: 35px;
    padding-top: 20px;
    @media #{$bp-l-min}{
        padding-top: 30px;
        padding-bottom: 50px;
    }
    &__wrap{
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        flex-direction: column;
        @media #{$bp-l-min}{
            flex-direction: row;
        }
    }
    &__1st{
        flex:1;
        width: 100%;
        @media #{$bp-l-min}{
            width: auto;
        }
        .entry__text--date{color:var(--color-highlight);}
    }
    &__2nd{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin-top: 8px;
        width: 100%;
        @media #{$bp-s-min}{
            margin-top: 4px;
            .entry__text--date{color:var(--color-highlight);}
        }
        @media #{$bp-l-min}{
            gap:10px;
            width: 32.5%;
            margin-top: 0;
            margin-left: 10px;
            flex-direction: column;
        }
    }
    &__3rd{
        margin-top: 25px;
        margin-bottom: 30px;
        // @media #{$bp-s-min}{margin-bottom: 0;}
        @media #{$bp-l-min}{
            // margin-top: 30px;
            display: flex;
            flex-wrap: wrap;
            gap:0 30px;
            .entry{width: calc(100% / 2 - 15px);}
            // column-count: 2;
            // column-gap: 1em;
        }
    }
    &__4th{
        display: none;
        @media #{$bp-s-min}{
            margin-top: 0;
            margin-bottom: 20px;
            display: flex;
            flex-wrap: wrap;
            gap:0 30px;
            .entry{width: calc(100% / 2 - 15px);}
            // display: block;
            // column-count: 2;
            // column-gap: 1em;
        }
        @media #{$bp-l-min}{
            display: flex;
            flex-wrap: wrap;
            gap:0 36px;
            .entry{width: calc(100% / 3 - 24px);}
            // column-count: 3;
            // column-gap: 1em;
        }
        .entry:nth-child(8){
            display: none;
            @media #{$bp-l-min}{
                display: block;
            }
        }
    }
}
