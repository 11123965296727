.layout{
    &__main{
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        box-sizing: content-box;
        max-width: $page-width-wide-max;
        margin: auto;
        padding:0 20px;
        &--medium{
            @extend .layout__main;
            max-width: $page-width-medium-max;
        }
        &--narrow{
            @extend .layout__main;
            max-width: $page-width-narrow-max;
        }
        &--minimum{
            @extend .layout__main;
            max-width: $page-width-minimum-max;
        }
    }
    &__fill_1-1{
        @media #{$bp-l-min}{
            display: flex;
            padding: 0 20px;
            >section{
                width: 50%;
                &::after{ width: calc(var(--vw) / 2); }
                &:first-child{
                    padding-right: 40px;
                    &::after{ left: calc(-1 * ((var(--vw) / 2) - 100%)); }
                }
                &:last-child{
                    padding-left: 40px;
                    &::after{ left: 0; }
                }
            }
            .top-section-publications__slider{
                margin-left: 0;
                margin-right: 0;
                .swiper{
                    width: 160px;
                }
            }
            @media(max-width: $page-width-wide-max){
                .top-section-publications__slider{
                    margin-left: -40px;
                    margin-right: -40px;
                }
            }
            &--content{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                &__top{}
                &__mid{}
                &__btm{}
            }
        }
    }
    &__article{
        display: flex;
        margin-bottom: 40px;
        @media #{$bp-s-min}{margin-bottom: 60px;}
        &--contents{
            flex:1;
            @media #{$bp-s-min}{
                display: grid;
                gap: 20px;
            }
            @media #{$bp-l-min}{gap: 40px 60px;}
            gap: 40px 60px;
            grid-template-columns: 212px 1fr;
            // grid-template-rows: repeat(4,auto-fit);
            // grid-template-rows: repeat(auto-fit);
            grid-template-rows: auto auto 1fr;
        }
        &--content1{
            order: 1;
            grid-row: 1;
            grid-column: 1 / span 2;
            margin-bottom: 40px;
            @media #{$bp-s-min}{
                margin-bottom: 0;
                .experts-detail &{margin-bottom: 25px;}
            }
            .no-image &{
                grid-column: 2;
            }
        }
        &--content2{
            order: 3;
            grid-row: 2;
            grid-column: 2;
            margin: 40px 0;
            @media #{$bp-s-min}{margin: 0;}
        }
        &--content3{
            order: 2;
            grid-row: 2 / span 2;
            grid-column: 1;
            // grid-column: 2 span 4 / 1;
            margin: 40px 0 20px;
            @media #{$bp-s-min}{margin: 0;}
            .no-image &{
                grid-row: 1 / span 4;
            }
        }
        &--content4{
            order: 4;
            grid-row: 3;
            grid-column: 2;
        }
        // &--meter{width: 0;}
    }
    &__about{
        margin-top: 35px;
        @media #{$bp-s-min}{
            margin-top: 40px;
            display: grid;
            grid-template-columns: 180px 1fr;
            grid-template-rows: repeat(2,auto-fit);
            gap: 0 20px;
        }
        @media #{$bp-l-min}{
            grid-template-columns: 230px 1fr;
            gap: 0 80px;
        }
        &--main{
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            margin-bottom: 35px;
            @media #{$bp-s-min}{
                grid-row: 1;
                grid-column: 2;
                margin-bottom: 60px;
            }
        }
        &--bottom{
            @media #{$bp-s-min}{
                grid-row: 2;
                grid-column: 1 / span 2;
            }
        }
        &--side{
            @media #{$bp-s-min}{
                grid-row: 1;
                grid-column: 1;
                margin-bottom: 60px;
            }
        }
    }
}
section{
    .layout__main > &,
    .layout__main--medium > &,
    .layout__main--narrow > &,
    .layout__fill_1-1 > &{
        @extend %bg-fill;
    }
}
