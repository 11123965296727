.ml{
    .headLine2, h2:not([class]), h2[class=" typesquare_option"]{
        margin-top: 1.25em;
        [lang="en"] &{
            &:first-child{margin-top: auto;}
            &:last-child{margin-bottom: auto;}
        }
    }
    &__list{
        display: flex;
        justify-content: space-between;
        gap: 5px;
        @media #{$bp-s-min}{gap: 10px;}
        // margin-top: 2em;
        &--item{
            width: calc(100% / 2 - 5px);
            @media #{$bp-s-min}{width: calc(100% / 2 - 10px);}
        }
        &--button{
            &.button--link{
                justify-content: center;
                &.stop{
                    background-color: #505050;
                }
            }
        }
    }
    &__main{
        padding: 30px 0 35px;
        @media #{$bp-s-min}{padding: 30px 0 40px;}
    }
    &__attention{
        padding: 35px 0;
        @media #{$bp-s-min}{padding: 50px 0;}
        --color-bg: var(--color-bg-gray);
        &--title{
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            margin-bottom: 0;
            &+p{margin-top: 0;}
        }
    }
}
