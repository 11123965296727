.notfound{
    &__content{
        text-align: center;
        margin-top: 25px;
        margin-bottom: 30px;
        @media #{$bp-s-min}{
            margin-top: 30px;
            margin-bottom: 50px;
        }
        .button__fill{
            margin-top: 6em;
        }
    }
}
