.program-detail{
    .layout__article--contents{
        @media #{$bp-s-min}{margin-top: -20px;}
    }
    .layout__article--content1{margin: 0;}
    .layout__article--content2{margin-top: 0;}
    &__title{
        [lang="ja"] &{ font-family: var(--font-ja-l); }
        font-weight: 400;
        font-size: 19px;
        line-height: 1.6;
        @media #{$bp-s-min}{ font-size: 26px; }
        @media #{$bp-l-min}{ font-size: 30px; }
        border-bottom: 1px solid var(--color-text);
        padding-bottom: 12px;
        margin-bottom: 30px;
        [lang="en"] &{
            font-weight: 500;
            line-height: 1.3;
        }
    }
    &__members{
        &--title{
            font-size: 16px;
            margin-top: 40px;
            @media #{$bp-s-min}{
                font-size: 15px;
                margin-top: 70px;
            }
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            &:first-child{margin-top: 0;}
            line-height: 1;
            position: relative;
            margin-bottom: 30px;
            &::after{
                content:"";
                width: 2.5em;
                height: 1px;
                background-color: var(--color-text);
                display: block;
                position: absolute;
                left: 0;
                bottom: -15px;
            }
        }
        .entry-expert{
            margin-top: 20px;
            &:first-child{margin-top: 0;}
            &__profile{
                margin-bottom: 0;
                &::after{display: none;}
                @media #{$bp-s-max}{
                    display: grid;
                    grid-template-rows: auto;
                    gap:0 15px;
                }
            }
            &__profile--image{
                margin-bottom: 0;
                grid-row: 1 / span 10;
            }
            &__profile--position{
                order: 2;
                font-size: 14px;
                @media #{$bp-s-min}{ font-size: 12px; }
            }
            &__profile--name{
                order: 1;
                @media #{$bp-s-max}{
                    margin-top: 0;
                }
            }
            &__profile--name-small{display: none;}
        }
        &--reader{
            .entry-expert__profile--name{
                font-size: 19px;
                line-height: 1.4;
                @media #{$bp-s-min}{ font-size: 18px; }
            }
            .entry-expert__profile{grid-template-columns: 150px 1fr;}
        }
        &--member{
            .entry-expert__profile--image{ max-width: 105px; }
            .entry-expert__profile--name{
                font-size: 17px;
                @media #{$bp-s-min}{ font-size: 16px; }
            }
            .entry-expert__profile{grid-template-columns: 102px 1fr;}
        }
    }
    &__recent{
        .common-recommend-entry__list--sigle{
            margin: 30px 0;
            &+.common-recommend-entry__list--sigle{
                margin-top: -10px;
            }
        }
        .common-recommend-entry.recent-content{
            padding-bottom: 0;
        }
    }
}
