input[type="text"]:not([class]),
input[type="email"]:not([class]),
textarea:not([class]){
    box-sizing: border-box;
    display: block;
    border: 1px solid var(--color-secondary);
    border-radius: 4px;
    width: 100%;
    background-color: #fff;
    padding: 12px 10px;
    @media #{$bp-s-min}{
        padding: 13px 20px;
    }
}
input[type="text"]:not([class]),
input[type="email"]:not([class]){
    line-height: 1;
}
textarea:not([class]){
    min-height: 100px;
    @media #{$bp-s-min}{
        min-height: 150px;
    }
}
.select{
    display: inline-block;
    border: 1px solid var(--color-secondary);
    position: relative;
    > select{
        line-height: 1;
        appearance: none;
        outline: none;
        border: none;
        border-radius: 0;
        padding: 17px 25px 17px 17px;
        background-color: #fff;
    }
    &::after{
        content : "";
        display: block;
        width: 5px;
        height: 5px;
        border-right: 1px solid var(--color-secondary);
        border-bottom: 1px solid var(--color-secondary);
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%) rotate(45deg);
        pointer-events: none;
    }
}
.input-date{
    display: inline-block;
    border: 1px solid var(--color-secondary);
    position: relative;
    z-index: 0;
    > input[type="date"],
    > input[type="month"]{
        line-height: 1;
        appearance: none;
        outline: none;
        border: none;
        border-radius: 0;
        position: relative;
        padding: 12px 54px 12px 12px;
        background-color: #fff;
        position: relative;
        z-index: 0;
        box-sizing: border-box;
        width: 150px;
        // &::before{
        //     content: attr(placeholder) !important;
        //     color: #777;
        //     width: 100%;
        // }
        &::-webkit-calendar-picker-indicator,
        &::-webkit-inner-spin-button,
        &::-webkit-clear-button,
        &::-webkit-clear-button{display: none; appearance: none;}
        &::-webkit-calendar-picker-indicator{
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;left: 0;
            opacity: 0;
        }
    }
    &::before{
        content : "";
        display: block;
        width: 40px;
        height: 100%;
        background-color: var(--color-secondary);
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        pointer-events: none;
    }
    &::after{
        content : "";
        display: block;
        width: 5px;
        height: 5px;
        border-right: 1px solid var(--color-highlight);
        border-bottom: 1px solid var(--color-highlight);
        position: absolute;
        top: 50%;
        right: 17px;
        z-index: 2;
        transform: translateY(-50%) rotate(45deg);
        pointer-events: none;
    }
}
