.read-meter{
    opacity: 0;
    .meter-show &{opacity: 1;}
    position: fixed;
    // z-index: 3;
    right: 7px;
    width: 3px;
    @media #{$bp-l-min}{
        right: 20px;
        width: 5px;
    }
    border-radius: 10px;
    background-color: #bbb;
    // transform: translateX(10px);
    // @media (min-width: $page-width-medium-max + 80px){transform: translateX(30px);}
    transition: .4s;
    top: calc(5vh + 50px);
    height: calc(90vh - 50px);
    @media #{$bp-l-min}{
        top: calc(5vh + 100px);
        height: calc(90vh - 100px);
    }
    .header-hidden &{
        top: 5vh;
        height: 90vh;
    }
    &__inner{
        position: absolute;
        top: 0;left: 50%;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background-color: var(--color-secondary);
        transform: translate(-50%);
        // transition: height .4s;
    }
    &__text{
        font-size: 10px;
        position: absolute;
        top: -4px;
        right: 13px;
        white-space: nowrap;
    }
}
