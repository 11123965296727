.sitemap{
    &__wrap{
        border-top: 1px solid #000;
        margin-top: 33px;
        column-gap: 30px;
        padding-top: 35px;
        margin-bottom: 35px;
        @media #{$bp-s-min}{
            margin-top: 30px;
            column-count: 3;
            padding-top: 40px;
            margin-bottom: 50px;
        }
    }
    &__list{
        page-break-inside: avoid;
        break-inside: avoid;
        margin-bottom: 2em;
        @media #{$bp-s-min}{margin-bottom: 3em;}
        &--title{
            line-height: 1.4;
            padding-bottom: .25em;
            border-bottom: 1px solid #b5b5b5;
            margin-bottom: -.2em;
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            font-size: 18px;
            @media #{$bp-s-min}{font-size: 16px;}
        }
        &--item{
            line-height: 1.4;
            margin: 1em 0;
            font-size: 15px;
            @media #{$bp-s-min}{font-size: 12px;}
            i{
                display: inline-block;
                width: 1em;
                margin-left: .25em;
                svg{
                    width: 10px;
                    fill: var(--color-primary);
                }

            }
        }
    }
}
