.entry-event{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:15px;
    z-index: 0;
    background-color: var(--color-highlight);
    padding: 10px;
    flex: 1;
    @media #{$bp-s-min}{ padding: 20px; gap:20px; }
    &.sumally{
        align-items: flex-start;
        padding: 10px 0;
        border-top: 1px solid var(--color-text);
        @media #{$bp-s-min}{ padding: 20px 0;}
    }
    .search__list &{
        border-top: 1px solid var(--color-text);
        padding: 20px 0;
        flex-direction: row-reverse;
        align-items: flex-start;
        &:last-child{padding-bottom: 0;}
    }
    &__permalink{
        // pointer-events: none;
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;left: 0;
        z-index: 1;
        overflow: hidden;
        text-indent: -9999px;
        &:hover{
            ~.entry-event__text{
                .entry-event__text--title{
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                }
            }
        }
    }
    &__icon{
        font-family: var(--font-family-serif);
        font-size: 12px;
        @media #{$bp-s-min}{ font-size: 20px; }
        line-height: 1;
        background-color: var(--color-highlight);
        border: 1px solid var(--color-primary);
        color: var(--color-primary);
        .past &{
            border: 1px solid var(--color-gray-text);
            color: var(--color-gray-text);
        }
        width: 6em;
        aspect-ratio: 1 / 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        text-align: center;
        .search__list &{
            aspect-ratio: 16 / 9;
            width: 100px;
            font-size: 10px;
            @media #{$bp-s-min}{
                width: 190px;
                font-size: 20px;
            }
        }
        &--day{
            font-size: 20px;
            @media #{$bp-s-min}{ font-size: 33px; }
            .search__list &{
                font-size: 15px;
                @media #{$bp-s-min}{ font-size: 33px; }
            }
        }
    }
    &__text{
        flex: 1;
        font-size: 13px;
        color: var(--color-text);
        @media #{$bp-s-min}{ font-size: 16px; }
        &--title{
            line-height: 1.6;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            [lang="ja"] &{ font-family: var(--font-ja-l); }
            font-weight: 500;
            .sumally &{
                @media #{$bp-s-min}{ font-size: 18px; }
            }
            .search__list &{
                font-size: 13px;
                @media #{$bp-s-min}{ font-size: 18px; }
            }
        }
        &--sumally,
        +.article{
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: #333;
            font-size: 10px;
            margin-top: 12px;
            @media #{$bp-s-min}{
                -webkit-line-clamp: 3;
                font-size: 12px;
                margin-top: 18px;
            }
            [lang="en"] &{
                line-height: 1.83;
            }
        }
        &--button{
            position: relative;
            background-color: #fff;
            z-index: 2;
            margin-top: 8px;
            @media #{$bp-s-min}{margin-top: 18px;}
            &--link{
                background-color: var(--color-secondary);
                color: var(--color-highlight);
                display: block;
                [lang="ja"] &{ font-family: var(--font-ja-b); }
                font-weight: 600;
                line-height: 1;
                text-align: center;
                padding: 15px;
                max-width: 375px;
                &:hover{ opacity: .9; }
            }
        }
    }
}
