.swiper{
    z-index: 0;
    &-wrapper{}
    &-swiper-slide{}
    &-button{
        &-prev,
        &-next{
            z-index: 1;
            &:before{
                content:"";
                width: 32px;
                height: 32px;
                background-color: rgba(#000,0.5);
                position: absolute;
                top: 0;
                z-index: 0;
                border-radius: 30px;
            }
            &:after{
                content: "";
                display: block;
                position: absolute;
                top: 10px;
                z-index: 1;
                width: 0;
                height: 0;
                border-style: solid;
            }
        }
        &-prev{
            &:before{
                left: 0;
            }
            &:after{
                left: 10px;
                border-width: 5px 8.7px 5px 0;
                border-color: transparent var(--color-highlight) transparent transparent;
            }
        }
        &-next{
            &:before{
                right: 0;
            }
            &:after{
                right: 10px;
                border-width: 5px 0 5px 8.7px;
                border-color: transparent transparent transparent var(--color-highlight);
            }
        }
    }
}
.slider{
    overflow: hidden;
    position: relative;
    z-index: 0;
    .swiper{
        position: static;
        // width: 100%;
        margin: auto;
        overflow: visible;
    }
}
.top-section-publications__slider.slider{
    .swiper-button-next,
    .swiper-button-prev{
        top: auto;
        bottom: 0;
    }

}
