.research{
    &__searchblock{
        margin: auto;
        margin-top: 40px;
        max-width: 568px;
    }
    &__searchtext{
        margin-top: 30px;
        text-align: center;
        color: var(--color-secondary);
        [lang="ja"] &{ font-family: var(--font-ja-b); }
        font-weight: 600;
        &--link{
            color: var(--color-secondary);
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            &:hover{
                text-decoration: underline;
            }
            &::after{
                content : "";
                display: block;
                width: 5px;
                height: 5px;
                border-right: 1px solid var(--color-secondary);
                border-bottom: 1px solid var(--color-secondary);
                transform: rotate(-45deg);
            }
        }
    }
    &__list{
        margin: 30px 0;
        @media #{$bp-s-min}{margin: 50px 0;}
    }
}
