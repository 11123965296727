.terms{
    .section-title__sub{
        margin-bottom: 33px;
        @media #{$bp-s-min}{margin-bottom: 40px;}
    }
    h3{
        color: var(--color-text);
        [lang="ja"] &{ font-family: var(--font-ja-b); }
        font-weight: 600;
        font-size: 16px;
        @media #{$bp-s-min}{font-size: 20px;}
    }
}
