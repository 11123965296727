.top-section-publications{
    padding: 35px 0;
    @media #{$bp-s-min}{padding: 50px 0;}
    --color-bg: var(--color-bg-gray);
    &__slider{
        margin-top: 30px;
        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: 20px;
        @media(min-width: $page-width-wide-max){
            margin-left: calc( -1 * (100vw - 100%) / 2 );
            margin-right: calc( -1 * (100vw - 100%) / 2 );
        }
        container: inline-size / slider-wrapper;
        .swiper{
            width: 180px;
            @media #{$bp-s-min}{
                width: 220px;
            }
        }
        .swiper-slide{
            opacity: .5;
            transition: opacity .6s;
            @container slider-wrapper size(min-width: 920px){
                opacity: 1;
            }
            &.swiper-slide-active,
            &.swiper-slide-prev,
            &.swiper-slide-next{
                opacity: 1;
            }
            &.swiper-slide-prev,
            &.swiper-slide-next{
                @media(max-width: 530px){
                    opacity: .5;
                }
            }
        }
    }
}
