.leadership{
    &__kv{
        margin-top: 25px;
        margin-bottom: 30px;
        @media #{$bp-s-min}{
            margin-top: 30px;
            margin-bottom: 50px;
        }
    }
    &__title{
        text-align: center;
        border-top: 1px solid var(--color-secondary);
        margin-top: 50px;
        padding-top: 25px;
        @media #{$bp-s-min}{
            margin-top: 58px;
            padding-top: 30px;
        }
    }
    &__button{
        @media #{$bp-s-min}{
            margin-top: 42px;
            max-width: 365px;
        }
    }
    .common-section-inquiries{
        margin-top: 50px;
        @media #{$bp-s-min}{
            margin-top: 70px;
        }
    }
}
