*,
*:before,
*:after{
    margin: 0;
    padding: 0;
    list-style: none;
    background-repeat: no-repeat;
    box-sizing:inherit;
    vertical-align:inherit;
    font-family: inherit;
    letter-spacing: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color:inherit;
    font-kerning: inherit;
    outline: none;
}
// html,body{ height: 100%; }
html{
    background-color: var(--color-highlight);
    scroll-behavior: smooth;
    font-kerning: normal;
    -webkit-text-size-adjust: 100%;
    vertical-align: baseline;
    box-sizing:border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // text-align: justify;
}
[id],a[name]{
    scroll-margin-top: 50+10px;
    @media #{$bp-s-min}{scroll-margin-top: 70+10px;}
    @media #{$bp-l-min}{scroll-margin-top: 100+10px;}
}
body{
    color: var(--color-text);
    font-family: var(--font-family);
    // [lang="ja"] &{ font-family: var(--font-family); }
    // [lang="en"] &{ font-family: var(--font-family); }
    font-weight: 400;
    font-style: normal;
    line-height: 1.93;
    letter-spacing: .005em;
    [lang="en"] &{
        // letter-spacing: 0;
        font-weight: 300;
    }
    font-size: 15px;
    @media #{$bp-s-min}{
        font-size: 16px;
        line-height: 2.31;
        [lang="en"] &{
            line-height: 2.19;
        }
    }
    // font-size: clamp( 14px, 2.25vw, 16px);
}
picture{display: block;overflow: hidden;}
