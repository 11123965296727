.global-footer{
    padding: 40px 20px 20px;
    background-color: var(--color-highlight);
    &__main{
        max-width: $page-width-wide-max;
        margin: auto;
        @media #{$bp-l-min}{
            display: flex;
            justify-content: space-between;
        }
    }
    &__sub{
        max-width: $page-width-wide-max;
        margin: 20px auto 0;
        @media #{$bp-l-min}{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: 60px;
        }
    }
    &__logo{
        margin: auto;
        width: 163px;
    }
    &__sns{
        display: flex;
        justify-content: center;
        @media #{$bp-l-min}{ justify-content: flex-start; }
        align-items: center;
        gap: 20px;
        line-height: 1;
        margin: 20px auto;
        &--item{
            width: 35px;
        }
        &--link{
            display: block;
            padding: 5px;
            svg{
                width: 100%;
                fill: #999;
            }
            &:hover svg{fill: #aaa;}
        }
    }
    &__sitemap-sp{
        display: flex;
        @media #{$bp-l-min}{display: none;}
        justify-content: center;
        align-items: stretch;
        flex-wrap: wrap;
        gap: 1px;
        padding: 1px 0;
        line-height: 1;
        background-color: #ddd;
        margin-left: -20px;
        margin-right: -20px;
        font-size: 13px;
        [lang="ja"] &{ font-family: var(--font-ja-b); }
        font-weight: 600;
        &--item{
            flex: 1 2 calc(50% - 1px);
            @media #{$bp-s-min}{
                flex: 1 2 calc(33% - 1px);
            }
            // &:last-child{
            //     @media #{$bp-s-max}{text-align: center;}
            // }
        }
        &--link{
            display: block;
            background-color: var(--color-highlight);
            padding: 20px;
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
    &__sitemap{
        display: flex;
        width: 50%;
        [lang="en"] &{width: 53%;}
        @media #{$bp-l-max}{display: none;}
        justify-content: space-between;
        font-size: 13px;
        line-height: 1;
        // gap: 0 2em;
        &--item{
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            &+&{margin-top: 1.5em;}
        }
        &--sub{
            font-size: 10px;
            line-height: 1.3;
            [lang="ja"] &{ font-family: var(--font-ja-m); }
            font-weight: 500;
            &+&{margin-top: .75em;}
            i{
                display: inline-block;
                width: 1em;
                svg{
                    width: 9px;
                    fill: var(--color-text);
                }
            }
        }
        &--subitem{
            &+&{margin-top: 1em;}
        }
        &--link{
            &:hover{
                text-decoration: underline;
                text-decoration-thickness: 1px;
            }
        }
    }
    &__banners{
        width: 260px;
        line-height: 1;
        margin: 30px auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap:10px 0;
        @media #{$bp-l-min}{
            width: 210px;
            margin: 0;
        }
        &--link{
            &:hover{opacity: .8;}
        }
    }
    &__pages{
        display: flex;
        justify-content: center;
        @media #{$bp-l-min}{ justify-content: flex-start; }
        align-items: center;
        font-size: 11px;
        line-height: 1;
        gap:2em;
        &--item{
            position: relative;
            &+&{
                &::before{
                    content: "｜";
                    position: absolute;
                    top: 0;
                    left: -1.5em;
                }
            }
        }
        &--link{
            &:hover{
                text-decoration: underline;
                text-decoration-thickness: 1px;
            }
        }
    }
    &__footnote{
        font-size: 10px;
        line-height: 1.4;
        margin-top: 20px;
    }
    &__copyright{
        font-size: 11px;
        [lang="ja"] &{font-family: var(--font-family-serif);}
        text-align: center;
        margin-top: 20px;
        @media #{$bp-l-min}{
            margin-top: 0;
        }
    }
}
