.events{
    &__list-title{
        line-height: 1;
        margin-bottom: .5em;
        font-size: 18px;
        @media #{$bp-s-min}{font-size: 24px;}
        .events__list+&{
            margin-top: 25px;
            @media #{$bp-s-min}{margin-top: 60px;}
        }
        &:first-of-type{
            margin-top: 35px;
            @media #{$bp-s-min}{margin-top: 50px;}
        }
    }
    &__list{
        margin: 0 0 30px;
        @media #{$bp-s-min}{margin: 0 0 50px;}
    }
    &__article{
        border-top: 1px solid var(--color-text);
        padding-top: 20px;
        margin: 28px 0;
        display: grid;
        grid-template-columns: 80px 1fr;
        grid-template-rows: auto auto;
        gap: 20px 16px;
        @media #{$bp-s-min}{
            padding-top: 30px;
            margin: 45px 0;
            grid-template-columns: 130px 1fr;
            gap: 26px 40px;
        }
        .events__icon{
            grid-row: 1;
            @media #{$bp-l-min}{ grid-row: 1 / span 2; }
            grid-column: 1;
        }
        .events__title{
            width: auto;
            grid-row: 1;
            grid-column: 2;
        }
        .events__contents{
            width: auto;
            grid-row: 2;
            grid-column: 1 / span 2;
            @media #{$bp-l-min}{ grid-column: 2; }
            &--button{
                &--link{
                    background-color: var(--color-secondary);
                    color: var(--color-highlight);
                    display: block;
                    [lang="ja"] &{ font-family: var(--font-ja-b); }
                    font-weight: 600;
                    line-height: 1;
                    text-align: center;
                    padding: 15px;
                    width: 70%;
                    max-width: 375px;
                    margin: auto;
                    @media #{$bp-l-min}{ margin-left: 0; }
                    margin-bottom: 1.5em;
                    &:hover{ opacity: .9; }
                }
            }
        }
    }
    &__icon{
        font-family: var(--font-family-serif);
        line-height: 1;
        font-size: 13px;
        width: 80px;
        @media #{$bp-s-min}{
            font-size: 21px;
            width: 130px;
        }
        aspect-ratio: 1 / 1;
        background-color: var(--color-highlight);
        border: 1px solid var(--color-primary);
        color: var(--color-primary);
        .past &{
            border: 1px solid var(--color-gray-text);
            color: var(--color-gray-text);
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        text-align: center;
        &--day{
            font-size: 22px;
            @media #{$bp-s-min}{ font-size: 35px; }
        }
    }
    &__title{
        [lang="ja"] &{ font-family: var(--font-ja-l); }
        font-weight: 400;
        line-height: 1.6;
        font-size: 19px;
        @media #{$bp-s-min}{ font-size: 30px; }
    }
    &__back-btn{
        margin: 32px 0;
        @media #{$bp-s-min}{ margin: 50px 0; }
    }
}
