.top-section-leadership{
    padding: 35px 0;
    @media #{$bp-s-min}{padding: 50px 0;}
    &__lead{
        margin-top: 20px;
        text-align: center;
        line-height: 1.46;
        @media #{$bp-s-min}{margin-top: 40px;}
    }
    &__list{
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        gap: 18px 24px;
        @media #{$bp-s-min}{
            margin-top: 40px;
            gap: 30px;
        }
        &--item{
            position: relative;
            z-index: 0;
            width: calc(100% / 2 - (24px / 2));
            @media #{$bp-s-min}{width: calc(100% / 3 - (30px * 2 / 3));}
            @media #{$bp-l-min}{width: calc(100% / 5 - (30px * 4 / 5));}
            &--link{
                display: block;
                position: absolute;
                top: 0;left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                &:hover{
                    ~.top-section-leadership__list--item--image .thumbnail__img{
                        transform: scale(1.05);
                    }
                    ~.top-section-leadership__list--item--title{text-decoration: underline;}
                }
            }
            &--logo{
                margin-bottom: 10px;
                @media #{$bp-s-min}{margin-bottom: 16px;}
                img{
                    max-width: 100%;
                }
            }
            &--image{
                position: relative;
                z-index: 0;
                width: 100%;
                aspect-ratio: 24 / 17;
            }
            &--date{
                line-height: 1;
                color: var(--color-gray-text);
                margin: 1.2em 0;
                font-size: 11px;
                @media #{$bp-s-min}{font-size: 12px;}
            }
            &--title{
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-weight: bold;
                line-height: 1.46;
                font-size: 13px;
                @media #{$bp-s-min}{font-size: 14px;}
            }
        }
    }
    &__developments{
        margin-top: 30px;
        @media #{$bp-s-min}{margin-top: 50px;}
        @media #{$bp-l-min}{
            display: flex;
            gap: 30px;
        }
        &--item{
            @media #{$bp-l-max}{
                text-align: center;
                &+&{margin-top: 30px;}
            }
            @media #{$bp-l-min}{
                width: calc(100% / 3 - (30px * 2 / 3));
            }
        }
        &--title{
            text-align: center;
            margin-bottom: 20px;
        }
        &--text{
            line-height: 1.46;
            font-size: 13px;
            @media #{$bp-s-min}{font-size: 16px;}
        }
    }
}
