.entry-expert{
    position: relative;
    z-index: 0;
    .search__list &{
        border-top: 1px solid var(--color-text);
        padding: 20px 0;
        &:last-child{padding-bottom: 0;}
    }
    &__permalink{
        // pointer-events: none;
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;left: 0;
        z-index: 1;
        overflow: hidden;
        text-indent: -9999px;
        &:hover{
        }
        .research-detail_author__expert &{display: none;}
    }
    &__profile{
        position: relative;
        margin-bottom: 28px;
        @media #{$bp-l-min}{ margin-bottom: 48px; }
        display: flex;
        flex-direction: column;
        .search__list &{
            margin-bottom: 0;
            &::after{display: none;}
            display: grid;
            gap: 0 17px;
            grid-template-columns: 1fr 56px;
            grid-template-rows: repeat(4,auto-fit);
            @media #{$bp-s-min}{ grid-template-columns: 1fr 100px; }
        }
        &--image{
            margin-bottom: 10px;
            @media #{$bp-l-min}{
                margin-bottom: 30px;
                .experts__list &{margin-bottom: 10px;}
            }
            font-size: 0;
            .search__list &{
                order: 2;
                grid-column: 2;
                grid-row: 1 / span 4;
                margin-bottom: 0;
            }
        }
        &--position{
            color: var(--color-secondary);
            line-height: 1.4;
            font-size: 13px;
            @media #{$bp-l-min}{ font-size: 12px; }
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            margin: .25em 0;
            .search__list &{
                order: 3;
                [lang="ja"] &{ font-family: var(--font-ja-l); }
                font-weight: 300;
                color: var(--color-primary);
                font-size: 10px;
                @media #{$bp-s-min}{ font-size: 13px; }
            }
            &+&{margin-top: 0;}
        }
        &--name{
            line-height: 1.2;
            font-size: 18px;
            @media #{$bp-l-min}{ font-size: 18px; }
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            margin: .25em 0;
            .search__list &{
                order: 1;
                [lang="ja"] &{ font-family: var(--font-ja-l); }
                font-weight: 300;
                font-size: 13px;
                @media #{$bp-s-min}{ font-size: 18px; }
                a{
                    color: var(--color-text);
                    text-decoration: none;
                }
            }
            a{
                .experts__list &{
                    color: var(--color-text);
                    text-decoration: none;
                }
                .entry-expert__permalink:hover ~ .entry-expert__profile &{
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                }
            }
        }
        &--name-small{
            [lang="ja"] &{ font-family: var(--font-ja-l); }
            font-weight: 400;
            line-height: 1.4;
            font-size: 11px;
            @media #{$bp-l-min}{ font-size: 12px; }
            margin: .25em 0;
            .search__list &{display: none;}
        }
        &::after{
            content:"";
            position: absolute;
            bottom: -14px;
            left: 0;
            display: block;
            height: 1px;
            width: 30px;
            @media #{$bp-l-min}{ width: 42px; bottom: -24px;}
            background-color: var(--color-text);
        }
    }
    &__other{
        margin-top: 16px;
        @media #{$bp-l-min}{ margin-top: 24px;}
        &--title{
            color: var(--color-secondary);
            line-height: 1.6;
            font-size: 13px;
            @media #{$bp-l-min}{ font-size: 12px; }
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            margin-bottom: .1em;
        }
        &--contents{
            color: rgba(#000,.8);
            line-height: 1.6;
            font-size: 14px;
            @media #{$bp-l-min}{ font-size: 14px; }
            .expert__field,
            .list__field,
            .list__unit{
                li{ display: inline; }
                li+li{
                    &::before{
                        content: " / ";
                    }
                    a{
                        position: relative;
                        z-index: 1;
                    }
                }
            }
            .experts & a{
                color: inherit;
                text-decoration: none;
            }
        }
        .article &{
            &--title,
            &--contents{
                font-size: 15px;
                @media #{$bp-s-min}{ font-size: 1rem; }
            }
        }
    }
}
