.csr{
    &__icon{
        text-align: center;
        color:var(--color-primary);
        font-size: 10px;
        line-height: 1;
        margin: 15px 0 30px;
        @media #{$bp-s-min}{margin: 20px 0 30px; }
        &--button{
            display: inline-block;
            background-color: var(--color-highlight);
            border: 1px solid rgba(#962238,.8);
            border-radius: 30px;
            padding: 4px 7px;
            @media #{$bp-s-min}{ padding: 5px 10px; }
            &:hover{
                background-color: #fafafa;
            }
        }
    }
    &__list{
        margin: 50px 0 55px;
        display: flex;
        flex-direction: column;
        gap: 50px;
        @media #{$bp-s-min}{
            flex-direction: row;
            flex-wrap: wrap;
        }
        &--item{
            position: relative;
            z-index: 0;
            @media #{$bp-s-min}{width: calc(50% - 25px);}
        }
        &--link{
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            &:hover{
                ~.csr__list--title,~.csr__list--text{text-decoration: underline;}
            }
        }
        &--image{
            position: relative;
            width: 100%;
            aspect-ratio: 16 / 9;
        }
        &--title{
            color:var(--color-primary);
            font-size: 18px;
            line-height: 1;
            margin: 20px 0 18px;
            @media #{$bp-s-min}{ font-size: 17px; }
        }
        &--text{
            font-size: 14px;
            @media #{$bp-s-min}{ font-size: 14px; }
        }
    }
    &__profilelist{
        &--item{
            margin-top: 30px;
            padding-bottom: 25px;
            border-bottom: 1px solid #b5b5b5;
            @media #{$bp-s-min}{
                display: grid;
                gap: 1.25rem 45px;
                grid-template-columns: 150px 1fr;
                grid-template-rows: repeat(2,auto);
            }
            .thumbnail-expert{
                width: 150px;
                margin: 0 auto 20px;
                @media #{$bp-s-min}{margin-bottom: 0;}
            }
            [lang="en"] &{
                padding-bottom: 0;
                border-bottom: 0;
            }
        }
        &--info{
            margin-bottom: 25px;
            text-align: center;
            @media #{$bp-s-min}{
                text-align: left;
                margin-bottom: 0;
            }
            .name{
                line-height: 1;
                font-size: 16px;
                [lang="ja"] &{ font-family: var(--font-ja-b); }
                font-weight: 600;
                @media #{$bp-s-min}{ font-size: 20px; }
            }
            .small{
                line-height: 1;
                margin-top: 10px;
                font-size: 10px;
                color: #b5b5b5;
            }
            .pos{
                text-align: left;
                margin: 1.25em 0 0;
            }
        }
        &--text{
            @media #{$bp-s-min}{
                grid-row: 2;
                grid-column: 1 / span 2;
            }
        }
    }
    &__table{
        border-collapse: collapse;
        line-height: 1.6;
        th,td{
            border-top: 1px solid #b5b5b5;
            border-bottom: 1px solid #b5b5b5;
            padding: 13px;
            @media #{$bp-s-min}{ padding: 20px; }
        }
        th{
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            background-color: #f2f2f2;
            text-align: left;
            width: 6.25em;
            [lang="en"] &{
                width: 10em;
            }
        }
    }
}
