@media print {
    .global-header-small,
    .global-header-small__searcharea,
    .global-header-large,
    .global-megamenu,
    .global-navigation,
    .global-breadcrumb,
    .global-footer,
    .st-sticky-share-buttons,
    .common-recommend-entry,
    .common-section-inquiries{
        display: none!important;
    }
    .layout__article--content1{
        display: grid;
    }
    p:not([class]),
    li:not([class]){break-inside: avoid;}
}
