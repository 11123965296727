.global-megamenu{
    position: fixed;
    z-index: 1;
    top: 0;left: 0;
    width: 100%;
    height: 100%;
    padding-top: 100px;
    transition: transform .4s;
    pointer-events: none;
    .megamenu-open &{pointer-events: auto;}
    transition: transform .4s;
    transform: translateY(0);
    .header-hidden &{ transform: translateY(-100px); }
    display: block;
    @media #{$bp-l-max}{display: none;}
    &__wrapper{
        position: absolute;
        top: 100px;left: 0;
        width: 100%;
        z-index: 1;
        padding: 40px 0;
        background-color: var(--color-highlight);
        box-shadow: 0px 10px 10px -10px rgba(0,0,0,0.5);
        transition: transform .4s,opacity 50ms linear .4s;
        transform: translateY(-120%);
        opacity: 0;
        .megamenu-open[data-megamenu='#mega-menu1'] &#mega-menu1{
            transition: transform .4s,opacity 50ms linear 0s;
            transform: translateY(0%);
            opacity: 1;
        }
        .megamenu-open[data-megamenu='#mega-menu2'] &#mega-menu2{
            transition: transform .4s,opacity 50ms linear 0s;
            transform: translateY(0%);
            opacity: 1;
        }
        .megamenu-open[data-megamenu='#mega-menu3'] &#mega-menu3{
            transition: transform .4s,opacity 50ms linear 0s;
            transform: translateY(0%);
            opacity: 1;
        }
    }
    &__close{
        position: absolute;
        top: 0;left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#000,.2);
        transition: opacity .4s;
        opacity: 0;
        .megamenu-open &{opacity: 1;}
    }
    &__list{
        --gap: 12px;
        --cellwidth: 218px;
        margin: auto;
        display: grid;
        // grid-auto-flow: column;
        grid-template-columns: 257px repeat(auto-fit,var(--cellwidth));
        grid-template-rows: repeat(2,49px);
        gap: var(--gap);
        width: calc(257px + var(--cellwidth) * (var(--columns) - 1) + var(--gap) * (var(--columns) - 1));
        [lang="en"] &{
            --cellwidth: 237px;
            grid-template-columns: 257px repeat(auto-fit,var(--cellwidth));
            grid-template-rows: repeat(2,70px);
            width: calc(257px + var(--cellwidth) * (var(--columns) - 1) + var(--gap) * (var(--columns) - 1));
        }
        &.no-large{grid-template-columns: repeat(auto-fit,var(--cellwidth));}
        &.columns1{--columns:1;}
        &.columns2{--columns:2;}
        &.columns3{--columns:3;}
        &.columns4{--columns:4;}
        &.columns5{--columns:5;}
        &--item{
            [lang="ja"] &{ font-family: var(--font-ja-l); }
            font-weight: 400;
            line-height: 1.4;
            font-size: 14px;
            &.large{
                font-size: 18px;
                grid-row: 1 / span 2;
                grid-column: 1;
            }
            i{
                display: inline-block;
                width: .75em;
                svg{
                    width: 10px;
                    fill: var(--color-text);
                }
            }
        }
        &--link{
            display: block;
            border: 1px solid var(--color-primary);
            --color-text: var(--color-primary);
            color: var(--color-text);
            height: 100%;
            padding: 0 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &::after{
                content : "";
                display: block;
                width: 5px;
                height: 5px;
                border-right: 1px solid var(--color-text);
                border-bottom: 1px solid var(--color-text);
                transform: rotate(-45deg);
            }
            &:hover{
                opacity: .95;
                text-decoration: underline;
                text-decoration-thickness: 1px;
            }
        }
    }
}
