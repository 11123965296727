.common-recommend-entry{
    padding: 35px 0;
    @media #{$bp-s-min}{padding: 50px 0;}
    // width: $page-width-medium-max;
    // max-width: 100vw;
    @media (min-width: #{$page-width-medium-max + 40px}){
        margin-left: calc( -1 * (#{$page-width-medium-max} - 100% ) / 2 ) !important;
        margin-right: calc( -1 * (#{$page-width-medium-max} - 100% ) / 2 ) !important;
        .layout__article--content4 &{
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }
    .research-detail &{
        &.program-related{--color-bg: var(--color-bg-gray);}
        &.thisauthor{
            padding-bottom: 0;
            @media #{$bp-s-min}{
                padding-top: 0;
            }
            .common-recommend-entry__list--single:last-child{margin-bottom: 0;}
        }
    }
    .layout__article +&{
        &.domain-related{padding-top: 0;}
    }
    .experts-detail &{
        &.thisauthor{--color-bg: var(--color-bg-gray);}
        // &.thisauthor{padding-bottom: 0;}
    }
    .program-detail &{
        &.domain-related{
            --color-bg: var(--color-bg-gray);
            padding-top: 35px;
            @media #{$bp-s-min}{padding-top: 50px;}
        }
    }
    .research-result &{
        &.reraled-programs{
            --color-bg: var(--color-bg-gray);
            // padding-top: 35px;
            // @media #{$bp-s-min}{padding-top: 50px;}
        }
    }
    .events &{
        &.event-report{--color-bg: var(--color-bg-gray);}
    }
    &.recent-content{
        .entry__text--tags{display: none;}
    }
    &.media{
        .entry-news__text--sumally{display: none;}
    }
    &__list{
        margin: 30px auto 10px;
        @media #{$bp-l-min}{
            // margin: 60px auto;
            display: flex;
            flex-wrap: wrap;
            gap:0 30px;
            .entry,
            .entry-news{width: calc(100% / 2 - 15px);}
        }
        &+&{
            margin-top: -10px;
            // margin-top: 20px;
            // @media #{$bp-l-min}{
            //     margin-top: -10px;
            // }
        }
        .entry__text--tags{ display: none; }
        &--single{
            margin: 30px auto;
            // @media #{$bp-l-min}{margin: 60px auto;}
            container: inline-size / entry-wrapper;
            &+&{
                margin-top: -10px;
                // @media #{$bp-l-min}{
                //     margin-top: -60px;
                // }
            }
            .entry__text--tags{ display: none; }
        }
        .reraled-programs &{
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0;
            max-width: 960px;
        }
    }
}
