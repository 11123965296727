.research-detail_title{
    position: relative;
    z-index: 0;
    @media #{$bp-s-min}{
        display: flex;
        justify-content: space-between;
    }
    &__thumbnail{
        margin-bottom: 40px;
        @media #{$bp-s-min}{
            margin-bottom: 0;
            width: 35%;
        }
        @media #{$bp-l-min}{
            width: 45%;
        }
        line-height: 1;
        &--image{
            position: relative;
            z-index: 0;
            width: 100%;
            aspect-ratio: 16 / 9;
        }
        &--caption{
            display: block;
            font-size: 10px;
            margin-top: 10px;
        }
    }
    &__info{
        @media #{$bp-s-min}{width: 62%;}
        @media #{$bp-l-min}{width: 52%;}
        .no-image &{ width: 100%; }
    }
    &__connection{
        display: flex;
        align-items: center;
        line-height: 1;
        gap: 10px;
        &--ribbon{
            background-color: var(--color-primary);
            font-size: 10px;
            @media #{$bp-s-min}{ font-size: 12px; }
            &--link{
                display: inline-block;
                padding: 4px 5px;
                color: var(--color-highlight);
                // position: relative;
                // z-index: 2;
                // &:hover{
                //     background-color: #333;
                // }
            }
        }
        &--program{
            font-size: 12px;
            @media #{$bp-l-min}{ font-size: 16px; }
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            // &--link{
            //     &:hover{ text-decoration: underline; }
            // }
        }
    }
    &__title{
        [lang="ja"] &{ font-family: var(--font-ja-l); }
        font-weight: 400;
        margin: 20px 0;
        font-size: 19px;
        line-height: 1.6;
        @media #{$bp-s-min}{ font-size: 30px; }
        [lang="en"] &{
            font-weight: 500;
            line-height: 1.38;
            font-size: 21px;
            @media #{$bp-s-min}{
                font-size: 32px;
                line-height: 1.31;
            }
        }
    }
    &__date{
        [lang="ja"] &{ font-family: var(--font-ja-l); }
        font-weight: 400;
        color: var(--color-gray-text);
        font-size: 10px;
        @media #{$bp-l-min}{ font-size: 13px; }
    }
    &__tags{
        @extend .entry__text--tags;
        margin-top: 10px;
        &--item{@extend .entry__text--tags--item;}
        &--link{@extend .entry__text--tags--link;}
    }
}
.research-detail_author{
    &__expert{
        .entry-expert+.entry-expert{
            margin-top: 30px;
            @media #{$bp-s-min}{
                margin-top: 60px;
            }
        }
        .entry-expert__profile{}
        .entry-expert__profile--image{order: 1;}
        .entry-expert__profile--position{order: 3;}
        .entry-expert__profile--name{order: 2;}
        .entry-expert__profile--name-small{display: none;}
    }
    &__title{
        font-size: 16px;
        [lang="ja"] &{ font-family: var(--font-ja-b); }
        font-weight: 600;
        letter-spacing: .05em;
        margin: 60px 0 14px;
        @media #{$bp-l-min}{
            font-size: 18px;
            margin: 130px 0 20px;
        }
        &:first-child{margin-top: 0;}
        &+.common-recommend-entry__list--single{
            margin: 0;
        }
    }
}
.research-detail_contents{
    &__point{
        background-color: var(--color-bg-gray);
        padding: 20px;
        font-size: 15px;
        margin-bottom: 3em;
        @media #{$bp-l-min}{
            margin-bottom: 5em;
        }
        @media #{$bp-l-min}{
            padding: 30px 34px;
            font-size: 16px;
        }
        [lang="en"] &{
            font-size: 15px;
            line-height: 1.8;
            margin-bottom: 1.5em;
            @media #{$bp-s-min}{
                font-size: 18px;
                line-height: 1.72;
                margin-bottom: 2em;
            }
        }
        &--title{
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            @media #{$bp-l-min}{
                font-size: 18px;
            }
        }
    }
}
