.tab{
    display: flex;
    gap: 6px;
    line-height: 1;
    font-size: 12px;
    @media #{$bp-s-min}{font-size: 14px;}
    [lang="ja"] &{ font-family: var(--font-ja-b); }
    font-weight: 600;
    color: var(--color-highlight);
    &__navi{
        flex: 1;
        text-align: center;
        font-size: 14px;
        &--button{
            display: block;
            padding: 13px 0;
            @media #{$bp-s-min}{padding: 18px 0;}
            background-color: #b5b5b5;
            &:hover{background-color: #a5a5a5;}
            &.active{
                background-color: var(--color-secondary);
                pointer-events: none;
            }
            &--thin{
                @extend .tab__navi--button;
                padding: 8px 0;
                @media #{$bp-s-min}{padding: 12px 0;}
            }
        }
    }
    &__target{
        display: none;
        &.active{
            display: block;
        }
    }
}
