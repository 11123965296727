.news{
    &__navigation{
        margin: 25px 0 20px;
        @media #{$bp-s-min}{
            margin: 30px 0;
        }
        &--title{
            cursor: pointer;
            background-color: var(--color-secondary);
            color: var(--color-highlight);
            line-height: 1;
            text-align: center;
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            font-size: 13px;
            padding: 12px 0;
            @media #{$bp-s-min}{
                font-size: 14px;
                padding: 13px 0;
            }
            &::after{
                content : "";
                display: inline-block;
                width: 5px;
                height: 5px;
                margin-left: 1em;
                border-right: 1px solid var(--color-highlight);
                border-bottom: 1px solid var(--color-highlight);
                transform: translateY(-50%) rotate(45deg);
                .navi-open &{transform: translateY(-45%) rotate(225deg);}
            }
        }
        &--contents{
            background-color: var(--color-bg-gray);
            text-align: center;
            padding: 12px;
            @media #{$bp-s-min}{padding: 25px 38px;}
            display: flex;
            justify-content: center;
        }
    }
    &__buttons{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        &--button{
            display: block;
            background-color: var(--color-highlight);
            color: var(--color-secondary);
            border: 1px solid var(--color-secondary);
            font-size: 14px;
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            line-height: 1;
            padding: 8px 0;
            width: 4em;
            &.active{
                background-color: var(--color-secondary);
                color: var(--color-highlight);
            }
        }
    }
    &__list{
        margin: 30px 0;
        @media #{$bp-s-min}{margin: 50px 0;}
        .entry-news{
            border-top: 1px solid var(--color-text);
        }
    }
    &__title{
        border-top: 1px solid var(--color-text);
        margin: 25px 0;
        padding-top: 12px;
        @media #{$bp-s-min}{
            margin: 30px 0 20px;
            // padding-top: 20px;
        }
        &--info{
            line-height: 1;
            font-size: 11px;
            @media #{$bp-s-min}{ font-size: 13px; }
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: .5em;
            margin-bottom: 4px;
        }
        &--ribbon{
            background-color: var(--color-primary);
            color: var(--color-highlight);
            padding: 2px 5px;
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            font-size: 10px;
            @media #{$bp-s-min}{ font-size: 12px; }
        }
        &--date{
            color: var(--color-gray-text);
            [lang="ja"] &{ font-family: var(--font-ja-l); }
            font-weight: 400;
        }
        &--title{
            [lang="ja"] &{ font-family: var(--font-ja-l); }
            font-weight: 500;
            line-height: 1.4;
            font-size: 19px;
            @media #{$bp-s-min}{ font-size: 30px; }
        }
    }
    &__back-btn{
        margin: 29px 0 33px;
        @media #{$bp-s-min}{
            margin: 48px 0 55px;
        }
    }
}
