.button{
    &--link{
        display: block;
    }
    &__fill{
        margin: auto;
        font-size: 14px;
        letter-spacing: .11em;
        text-align: center;
        font-weight: 600;
        line-height: 1;
        .button--link{
            cursor: pointer;
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            background-color: var(--color-secondary);
            color: var(--color-highlight);
            padding: 20px 20px;
            appearance: none;
            outline: none;
            border: none;
            border-radius: 0;
            width: 100%;
            box-sizing: border-box;
            i{
                display: block;
                width: 20px;
                height: 100%;
                position: relative;
                svg{
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
            }
            svg{fill: var(--color-highlight);}
            &:hover{
                opacity: .9;
            }
            &.loading{
                pointer-events: none;
                background-color: var(--color-gray-text);
                color: var(--color-gray-text);
                position: relative;
                &::after{
                    content:"";
                    display: block;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 0;left: 0;right: 0;bottom: 0;
                    margin: auto;
                    background-image: url(../../img/loading_oval.svg);
                    background-size: contain;
                }
            }
        }
        &.light{
            .button--link{
                background-color: var(--color-highlight);
                color: var(--color-text);
                svg{fill: var(--color-text);}
                &:hover{
                    opacity: .95;
                }
            }
        }
        &.ghost{
            .button--link{
                background-color: transparent;
                color: var(--color-secondary);
                border: 1px solid var(--color-secondary);
                svg{fill: var(--color-secondary);}
                &:hover{
                    background-color: rgba(#03043f,.05);
                }
            }
            &.light .button--link{
                color: var(--color-highlight);
                border: 1px solid var(--color-highlight);
                svg{fill: var(--color-highlight);}
                &:hover{
                    background-color: rgba(#fff,.05);
                }
            }
        }
        &--thin{
            @extend .button__fill;
            font-size: 13px;
            .button--link{
                padding: 12px 20px;
                i{ width: 14px; }
            }
        }
    }
}
