.common-section-research-domains{
    padding: 35px 0;
    @media #{$bp-s-min}{padding: 50px 0;}
    --color-bg: var(--color-gray-text);
    --color-text: var(--color-highlight);
    color: var(--color-text);
    .about &{
        @extend %bg-fill;
        --color-bg: var(--color-gray-text);
    }
    &__title{
        line-height: 1;
        font-size: 14px;
        [lang="ja"] &{ font-family: var(--font-ja-b); }
        font-weight: 600;
        letter-spacing: .1em;
        margin-bottom: 1em;
        @media #{$bp-s-min}{
            font-size: 21px;
        }
        text-align: center;
        [lang="en"] &{
            font-family: var(--font-family-serif);
            font-weight: 400;
            letter-spacing: .05em;
            text-transform: uppercase;
            font-size: 25px;
            // margin: 1em auto .25em;
            @media #{$bp-s-min}{
                font-size: 34px;
            }
        }
    }
    &__list{
        max-width: 810px;
        margin: auto;
        font-size: 10px;
        [lang="ja"] &{ font-family: var(--font-ja-b); }
        font-weight: 600;
        line-height: 1.4;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        @media #{$bp-s-min}{font-size: 15px;}
        &--item{
            width: calc(100% / 2 - 5px);
            min-height: 45px;
            @media #{$bp-s-min}{
                min-height: 70px;
                width: calc(100% / 3 - 10px);
            }
        }
        &--link{
            box-sizing: border-box;
            display: block;
            height: 100%;
            border: 1px solid var(--color-highlight);
            padding: 8px 15px;
            display: flex;
            align-items: center;
            // justify-content: center;
        }
    }
}
