.inquiry{
    &__hr{
        border: none;
        background-color: #b5b5b5;
        width: 100%;
        height: 1px;
        max-width: $page-width-wide-max;
        margin: auto;
        // @media #{$bp-l-min}{
        //     width: calc(100% - 40px);
        // }
    }
    &__read{
        border-top: 1px solid var(--color-text);
        padding-top: 20px;
        margin: 28px 0;
        @media #{$bp-s-min}{
            padding-top: 15px;
            margin: 30px 0;
        }
    }
    &__form{}
    &__list{
        &--title{
            line-height: 1;
            margin-top: 25px;
            @media #{$bp-s-min}{margin-top: 30px;}
            &:first-child{margin-top: 0;}
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            i{
                font-style: normal;
                color: var(--color-primary);
            }
        }
        &--item{
            margin-top: 10px;
            @media #{$bp-s-min}{margin-top: 12px;}
            .select-modal__data{margin-top: 15px;}
            .confirm &.confirm,
            .confirm & .confirm{
                padding: 10px;
                background-color: var(--color-bg-gray);
            }
            .confirm & .confirm_list{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 20px;
                .confirm{
                    box-sizing: border-box;
                    text-align: center;
                    width: calc( 100% / 2 - (20px *(2 - 1) / 2) );
                }
            }
        }
    }
    &__submit{
        margin: 50px 0;
        @media #{$bp-s-min}{margin: 35px 0;}
        .button--link{
            justify-content: center;
            max-width: 560px;
            margin: auto;
        }
    }
}
