.select-modal{
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0s;
    opacity: 0;
    pointer-events: none;
    .modal-open &{
        transition: opacity .4s;
        opacity: 1;
        pointer-events: auto;
    }
    position: fixed;
    top: 0;left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    box-sizing: border-box;
    overflow-y: auto;
    background-color: rgba(#03043f,.9);
    padding: 30px 20px;
    @media #{$bp-s-min}{
        padding: 40px 40px;
    }
    @media #{$bp-l-min}{
        padding: 60px 60px;
    }
    &__inner{
        padding: 20px 20px 0;
        @media #{$bp-l-min}{
            padding: 40px 60px 0;
        }
        background-color: #fff;
        position: relative;
        flex: 1;
        max-width: 1080px;
        margin: auto;
    }
    &__close{
        position: absolute;
        top: 20px;
        right: 20px;
        width: 60px;
        height: 60px;
        transform: rotate(45deg);
        &::before,&::after{
            content:"";
            display: block;
            width: 100%;
            height: 1px;
            background-color: var(--color-secondary);
            position: absolute;
            top: 50%;
            left: 0;
        }
        &::after{transform: rotate(90deg);}
    }
    &__button{
        margin-top: 20px;
        position: sticky;
        left: 0;
        bottom: -40px;
        background-color: #fff;
        padding-bottom: 30px;
        padding-top: 10px;
        @media #{$bp-l-min}{
            bottom: -100px;
            padding-bottom: 60px;
        }
        button.button--link{
            position: relative;
            cursor: pointer;
            appearance: none;
            outline: none;
            border: none;
            width: 100%;
            box-sizing: border-box;
            justify-content: center;
            i{
                display: block;
                width: 14px;
                height: 14px;
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
                [lang="en"] &{transform: translateY(-50%) rotate(45deg) scale(1.5);}
                &::before,&::after{
                    content:"";
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: var(--color-highlight);
                    position: absolute;
                    top: 50%;
                    left: 0;
                }
                &::after{transform: rotate(90deg);}
            }
        }
    }
    &__trigger{
        box-sizing: border-box;
        line-height: 1;
        text-align: center;
        display: block;
        cursor: pointer;
        color: var(--color-secondary);
        background-color: var(--color-highlight);
        border: 1px solid var(--color-secondary);
        padding: 14px 10px;
        @media #{$bp-s-min}{
            padding: 16px 20px;
        }
        position: relative;
        &:hover{
            text-decoration: underline;
            text-decoration-thickness: 1px;
        }
        i{
            display: block;
            width: 14px;
            height: 14px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            &::before,&::after{
                content:"";
                display: block;
                width: 100%;
                height: 1px;
                background-color: var(--color-secondary);
                position: absolute;
                top: 50%;
                left: 0;
            }
            &::after{transform: rotate(90deg);}
        }
    }
    &__list,
    &__data{
        display: flex;
        flex-wrap: wrap;
        line-height: 1;
        gap: 15px;
        @media #{$bp-s-min}{gap: 20px;}
        margin-top: 1.5em;
        input[type="checkbox"],
        input[type="radio"]{
            display: none;
            &+label{
                box-sizing: border-box;
                display: none;
                cursor: pointer;
                color: var(--color-secondary);
                background-color: var(--color-highlight);
                border: 1px solid var(--color-secondary);
                padding: 14px 10px;
                @media #{$bp-s-min}{padding: 16px 10px;}
                // display: flex;
                align-items: center;
                justify-content: center;
                line-height: 1.2;
                position: relative;
                &:hover{
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                }
                i{
                    display: none;
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%) rotate(45deg);
                    &::before,&::after{
                        content:"";
                        display: block;
                        width: 100%;
                        height: 1px;
                        background-color: var(--color-highlight);
                        position: absolute;
                        top: 50%;
                        left: 0;
                    }
                    &::after{transform: rotate(90deg);}
                }
            }
            &:checked+label{
                color: var(--color-highlight);
                background-color: var(--color-secondary);
                display: flex;
                padding-right: 50px;
                &:hover{
                    text-decoration: line-through;
                    text-decoration-thickness: 1px;
                }
                i{
                    display: block;
                }
            }
        }
        &#expertlist,[data-modal="expert"] &{
            label{
                padding-right: 10px;
                width: calc( 100% / 2 - (20px *(2 - 1) / 2) );
                @media #{$bp-s-min}{width: calc( 100% / 3 - (20px *(3 - 1) / 3) );}
                @media #{$bp-l-min}{width: calc( 100% / 4 - (20px *(4 - 1) / 4) );}
                [lang="en"] &{
                    @media #{$bp-s-min}{width: calc( 100% / 2 - (20px *(2 - 1) / 2) );}
                    @media #{$bp-l-min}{width: calc( 100% / 2 - (20px *(2 - 1) / 2) );}
                }
            }
        }
        [data-modal="expert"] & label{
            [lang="en"] &{
                // @media #{$bp-s-min}{width: calc( 100% / 2 - (20px *(2 - 1) / 2) );}
                @media #{$bp-s-min}{width: calc( 100% / 3 - (20px *(3 - 1) / 3) );}
                @media #{$bp-l-min}{width: calc( 100% / 4 - (20px *(4 - 1) / 4) );}
            }
        }
        &#expertlist label{
            padding-right: 40px;
            // i{right: 12px;}
        }
        &#programlist,[data-modal="program"] &{
            label{
                width: 100%;
                justify-content: flex-start!important;
                @media #{$bp-s-min}{padding-left: 20px;}
            }
        }
        &#rdlist{
            label{
                width: 100%;
                text-align: center;
                @media #{$bp-l-min}{width: calc( 100% / 2 - (20px *(2 - 1) / 2) );}
                [lang="en"] &{ text-align: left; }
            }
        }
        &#classification{
            label{
                flex: 1;
                text-align: center;
            }
        }
        &#rdlist,
        &#classification{
            input[type="checkbox"]+label,input[type="radio"]+label{display: block;}
            input[type="checkbox"]:checked+label,input[type="radio"]:checked+label{
                padding-right: 10px;
                @media #{$bp-s-min}{padding-right: 10px;}
                &:hover{ text-decoration: underline; }
                i{ display: none; }
            }
        }
    }
    &__list{
        input[type="checkbox"]+label,input[type="radio"]+label{display: flex;}
        input[type="checkbox"]:checked+label,input[type="radio"]:checked+label{
            padding-right: 10px;
            @media #{$bp-s-min}{padding-right: 10px;}
            &:hover{ text-decoration: underline; }
            i{ display: none; }
        }
    }
}
