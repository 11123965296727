.entry-news{
    box-sizing: border-box;
    position: relative;
    z-index: 0;
    background-color: var(--color-highlight);
    padding: 20px 5px 16px;
    flex: 1;
    .common-recommend-entry__list &,
    .common-recommend-entry__list--sigle &{
        flex: none;
        border-top: 1px solid var(--color-text);
    }
    .search__list &{
        border-top: 1px solid var(--color-text);
        padding: 20px 0;
        &:last-child{padding-bottom: 0;}
    }
    // @media #{$bp-s-min}{ padding: 20px; }
    &__permalink{
        // pointer-events: none;
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;left: 0;
        z-index: 1;
        overflow: hidden;
        text-indent: -9999px;
        &:hover{
            ~.entry-news__text{
                .entry-news__text--title{
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                }
            }
        }
    }
    &__info{
        line-height: 1;
        font-size: 10px;
        @media #{$bp-l-min}{ font-size: 12px; }
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1em;
        margin-bottom: 10px;
        .search__list &{
            font-size: 10px;
            @media #{$bp-s-min}{ font-size: 12px; }
        }
        &--ribbon{
            background-color: var(--color-primary);
            color: var(--color-highlight);
            padding: 2px 5px;
            #tab-list-events &{display: none;}
        }
        &--date{
            color: var(--color-gray-text);
            [lang="ja"] &{ font-family: var(--font-ja-l); }
            font-weight: 400;
        }
    }
    &__text{
        &--title{
            line-height: 1.6;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            [lang="ja"] &{ font-family: var(--font-ja-l); }
            font-weight: 500;
            .search__list &{
                font-size: 13px;
                @media #{$bp-s-min}{ font-size: 18px; }
            }
        }
        &--sumally,
        +.article{
            // display: -webkit-box;
            // -webkit-line-clamp: 7;
            // -webkit-box-orient: vertical;
            // overflow: hidden;
            color: #333;
            font-size: 10px;
            margin-top: 12px;
            @media #{$bp-s-min}{
                // -webkit-line-clamp: 3;
                font-size: 12px;
                margin-top: 18px;
            }
            [lang="en"] &{
                line-height: 1.83;
            }
        }
    }
}
