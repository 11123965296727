.experts{
    &__tabs{
        margin: 28px 0;
        @media #{$bp-s-min}{
            margin: 45px 0;
        }
    }
    &__title{
        line-height: 1.4;
        font-size: 18px;
        margin-bottom: 23px;
        @media #{$bp-s-min}{
            font-size: 24px;
            margin-bottom: 30px;
        }
        padding-bottom: .25em;
        border-bottom: 1px solid var(--color-text);
        [lang="en"] &{ font-weight: 500; }
    }
    &__list{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 45px;
        @media #{$bp-l-min}{
            gap: 45px;
            margin-bottom: 50px;
        }
        .entry-expert{
            width: calc( 100% / 2 - ( 20px * (2 - 1) / 2) );
            @media #{$bp-s-min}{
                width: calc( 100% / 3 - ( 20px * (3 - 1) / 3) );
            }
            @media #{$bp-l-min}{
                width: calc( 100% / 5 - ( 45px * (5 - 1) / 5 ) );
            }
        }
    }
}
