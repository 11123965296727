.pager{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-secondary);
    line-height: 1;
    font-size: 11px;
    margin: 30px auto;
    gap: 4px;
    @media #{$bp-s-min}{
        font-size: 14px;
        margin: 50px auto;
        gap: 10px;
    }
    &__item{
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--color-secondary);
        width: 30px;
        height: 30px;
        @media #{$bp-s-min}{
            width: 40px;
            height: 40px;
        }
        &.link2nd,
        &.link3rd{
            display: none;
        }
        &.link2nd{
            @media(min-width: 420px){display: flex;}
        }
        &.link3rd{
            @media #{$bp-s-min}{display: flex;}
        }
        &.prev,
        &.next{
            width: 50px;
            // overflow: hidden;
            // text-indent: -999px;
            // position: relative;
            @media #{$bp-s-min}{
                width: 70px;
                text-indent: 0;
            }
        }
        &.extend{
            &::before{ content:"…"; }
            width: 1.25em;
            border: none;
            pointer-events: none;
        }
        &.current{
            background-color: var(--color-secondary);
            color: var(--color-highlight);
            pointer-events: none;
        }
        &:hover{
            background-color: var(--color-secondary);
            color: var(--color-highlight);
        }
    }
}
