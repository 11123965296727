.top-section-search{
    padding: 35px 0;
    @media #{$bp-s-min}{padding: 50px 0;}
    --color-bg: var(--color-gray-text);
    color: var(--color-highlight);
    .section-title{
        @media #{$bp-s-max}{margin-bottom: .5em;}
    }
    .freeword-search{
        // margin-top: 30px;
    }
    &__head{
        font-size: 14px;
        [lang="ja"] &{ font-family: var(--font-ja-b); }
        font-weight: 600;
        letter-spacing: .1em;
        margin: 2em auto 1em;
        @media #{$bp-l-min}{margin: 5em auto 2em;}
        text-align: center;
        [lang="en"] &{
            // text-transform: uppercase;
            font-family: var(--font-family-serif);
            font-weight: 400;
            letter-spacing: .05em;
            font-size: 18px;
            margin: 2em auto .5em;
            @media #{$bp-s-min}{
                font-size: 26px;
            }
        }
    }
    &__list{
        font-size: 10px;
        [lang="ja"] &{ font-family: var(--font-ja-b); }
        font-weight: 600;
        line-height: 1.4;
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 20px;
        &--item{
            width: calc(100% / 2 - 5px);
            min-height: 50px;
            @media #{$bp-s-min}{
                width: calc(100% / 3 - 10px);
            }
        }
        &--link{
            box-sizing: border-box;
            display: block;
            height: 100%;
            border: 1px solid var(--color-highlight);
            padding: 8px 15px;
            display: flex;
            align-items: center;
            [lang="en"] &{padding: 8px 10px;}
            // justify-content: center;
        }
    }
}
