.search{
    &__list{
        margin: 30px 0;
        @media #{$bp-s-min}{margin: 50px 0;}
        &--item{
            font-size: 13px;
            @media #{$bp-s-min}{ font-size: 18px; }
            &:last-child a{padding-bottom: 0;}
        }
        &--link{
            display: block;
            border-top: 1px solid var(--color-text);
            padding: 20px 0;
            &:hover{text-decoration: underline;}
        }
    }
}
