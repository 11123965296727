.entry-program{
    width: 100%;
    position: relative;
    z-index: 0;
    border-top: 1px solid var(--color-text);
    padding: 20px 0;
    &:last-child{padding-bottom: 0;}
    &__permalink{
        display: block;
        // .sublist &{display: none;}
        position: absolute;
        top: 0;left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        text-indent: -9999px;
        // background-color: rgba(red,.2);
        .slide-open &{
            height: calc(100% - 20px);
        }
        &:hover{
            ~.entry-program__main{
                .entry-program__info--title{
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                }
            }
        }
    }
    &__main{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 17px;
        .search__list &{
            flex-direction: row-reverse;
        }
        .thumbnail-expert{
            width: 56px;
            @media #{$bp-s-min}{width: 100px;}
        }
        .entry-program__info{flex: 1;}
        .entry-program__icon{
            width: 40px;
            @media #{$bp-s-min}{width: 50px;}
        }
        .sublist &{cursor: pointer;}
    }
    &__sub{
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 17px;
        position: relative;
        z-index: 1;
        background-color: #fff;
        &::before,&::after{
            content:"";
            display: block;
        }
        &::before{
            width: 56px;
            @media #{$bp-s-min}{width: 100px;}
        }
        &--inner{
            padding-top: 20px;
            flex: 1;
        }
        &::after{
            width: 40px;
            @media #{$bp-s-min}{width: 50px;}
        }
    }
    &__info{
        &--label{
            line-height: 1;
            font-size: 10px;
            margin-bottom: 8px;
            @media #{$bp-l-min}{font-size: 12px;}
            i{
                [lang="ja"] &{ font-family: var(--font-ja-b); }
                font-weight: 500;
                font-style: normal;
                display: inline-block;
                background-color: var(--color-primary);
                color: var(--color-highlight);
                padding: 4px 10px;
            }
        }
        &--title{
            [lang="ja"] &{ font-family: var(--font-ja-l); }
            font-weight: 500;
            line-height: 1.6;
            @media #{$bp-l-min}{font-size: 18px;}
            .search__list &{
                font-size: 13px;
                @media #{$bp-s-min}{ font-size: 18px; }
            }
        }
        &--expert{
            [lang="ja"] &{ font-family: var(--font-ja-l); }
            font-weight: 400;
            line-height: 1;
            color: var(--color-primary);
            font-size: 10px;
            @media #{$bp-l-min}{font-size: 12px;}
            margin-top: 8px;
            .search__list &{
                font-size: 10px;
                @media #{$bp-s-min}{ font-size: 13px; }
            }
        }
    }
    &__icon{
        width: 100%;
        aspect-ratio: 1 / 1;
        background-color: var(--color-secondary);
        position: relative;
        &::before,&::after{
            content:"";
            display: block;
            width: 50%;
            height: 1px;
            background-color: var(--color-highlight);
            position: absolute;
            top: 0;left: 0;right: 0;bottom: 0;
            margin: auto;
        }
        &::after{
            transform: rotate(90deg);
            .slide-open &{display: none;}
        }
        &:hover{opacity: .9;}
    }
    &__sub--inner{
        .button__fill{max-width: 212px;margin-left: 0;}
        .button--link{
            @media #{$bp-l-max}{
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    &__sublist-title{
        line-height: 1;
        font-size: 13px;
        [lang="ja"] &{ font-family: var(--font-ja-b); }
        font-weight: 600;
        margin: 0 0 1em;
        @media #{$bp-l-min}{font-size: 16px;}
    }
    &__sublist{
        .entry{padding: 10px 0;}
        .entry__text--tags{display: none;}
    }
}
