.common-section-inquiries{
    padding: 35px 0;
    @media #{$bp-s-min}{padding: 50px 0;}
    --color-bg: transparent;
    color: var(--color-highlight);
    &__text{
        text-align: center;
        margin: 30px auto;
        [lang="ja"] &{ font-family: var(--font-ja-l); }
        font-weight: 400;
    }
    .button__fill{
        max-width: 400px;
    }
    &__bg-image{
        position: absolute;
        top: 0;
        left: calc( -1 * (var(--vw) - 100%) / 2 );
        width: var(--vw);
        height: 100%;
        z-index: -1;
        &--img{
            width: 100%;height: 100%;
            object-fit: cover;
        }
    }
}
