.global-navigation{
    --nav-height: 50px;
    display: block;
    @media #{$bp-l-min}{display: none;}
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    top: 0;left: 0;
    z-index: 1;
    background-color: var(--color-secondary);
    --color-text: var(--color-highlight);
    padding-top: 50px;
    @media #{$bp-s-min}{padding-top: 70px;}
    line-height: 1.2;
    font-size: 13px;
    [lang="ja"] &{ font-family: var(--font-ja-b); }
    font-weight: 600;
    transition: transform .4s;
    transform: translateX(-101%);
    .menu-open &{ transform: translateX(0); }
    &__list{
        &--item{}
        &--link{
            border-top: 1px solid var(--color-highlight);
            background-color: var(--color-secondary);
            color:var(--color-text);
            height: var(--nav-height);
            padding: 0 20px;
            display: flex;
            align-items: center;
            .center &{
                justify-content: center;
                font-size: 14px;
            }
            i{
                width: 1.25em;
                margin-right: .5em;
                svg{
                    width: 16px;
                    fill: var(--color-text);
                }
            }
            &.toggle{
                justify-content: space-between;
                &::after{
                    content : "";
                    display: block;
                    width: 5px;
                    height: 5px;
                    border-right: 1px solid var(--color-highlight);
                    border-bottom: 1px solid var(--color-highlight);
                    transform: rotate(45deg);
                    .toggle-open &{ transform: rotate(225deg); }
                }
            }
        }
        &.flex{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            grid-template-rows: repeat(2,var(--nav-height));
            gap: 0 1px;
            background-color: var(--color-highlight);
        }
    }
    &__sublist{
        display: none;
        &--item{
        }
        &--link{
            border-top: 1px solid var(--color-secondary);
            background-color: var(--color-highlight);
            color: var(--color-secondary);
            height: var(--nav-height);
            padding: 0 20px 0 40px;
            display: flex;
            align-items: center;
            i{
                width: .75em;
                margin-left: 5px;
                svg{
                    width: 9px;
                    fill: var(--color-secondary);
                }
            }
        }
    }
    &__lang-swich{
        border-top: 1px solid var(--color-highlight);
        background-color: var(--color-secondary);
        padding: 20px;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(1,30px);
        gap: 5px;
        &--link{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #b5b5b5;
            color: var(--color-highlight);
            height: 100%;
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            [lang="ja"] &.ja{ background-color: var(--color-highlight); color: var(--color-secondary); pointer-events: none; }
            [lang="en"] &.en{ background-color: var(--color-highlight); color: var(--color-secondary); pointer-events: none; }
        }
    }
}
