.search-advanced{
    .freeword-search{
        max-width: 566px;
        margin: auto;
    }
    // &__title,
    // .select-modal__title{
    //     font-size: 16px;
    //     @media #{$bp-s-min}{font-size: 20px;}
    //     [lang="ja"] &{ font-family: var(--font-ja-b); }
    //     font-weight: 600;
    //     border-bottom: 1px solid var(--color-secondary);
    //     margin: 1.25em 0 1em;
    // }
    &__year-month-list{
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // &.pc{
        //     display: none;
        //     @media #{$bp-s-min}{display: flex;}
        // }
        // &.sp{
        //     display: none;
        //     @media #{$bp-s-max}{display: flex;}
        // }
        @media #{$bp-s-max}{
            // display: block;
            flex-wrap: wrap;
            gap: 10px;
        }
        &--item{
            position: relative;
            &:first-child{
                &::after{
                    content:"〜";
                    position: absolute;
                    top: 50%;
                    right: -1.5em;
                    transform: translateY(-50%);
                }
                margin-right: 2em;
                [lang="en"] &{margin-right: 1.25em;}
            }
            .select{
                // color: #b5b5b5;
                color: #757575;
                [lang="en"] &{
                    margin-left: .5em;
                    >select{
                        padding-right: 20px;
                    }
                }
            }
            @media #{$bp-s-max}{
                display: inline-block;
                font-size: .9em;
                .select{
                    [lang="ja"] &{
                        margin-left: -.75em;
                        margin-right: -.75em;
                    }
                    [lang="en"] &{
                        margin-left: .5em;
                    }
                    &:first-child{margin-left: 0;}
                    &::after{
                        right: 5px;
                        transform: scale(.9) translateY(-50%) rotate(45deg);
                    }
                    >select{
                        padding: 12px 15px 12px 10px;
                    }
                }
            }
        }
    }
    &__listmodal{
        width: 100%;
        line-height: 1;
        @media #{$bp-s-min}{
            width: 50%;
        }
    }
    &__buttons{
        display: flex;
        justify-content: space-between;
        gap: 20px;
        max-width: 850px;
        margin: 45px auto 35px;
        flex-direction: column;
        @media #{$bp-s-min}{
            flex-direction: row;
            margin: 80px auto 70px;
        }
        &--item{
            width: 10em;
            &:nth-child(2){
                flex:1;
                width: 100%;
                @media #{$bp-s-min}{
                    width: 50%;
                }
            }
            button{
                cursor: pointer;
                appearance: none;
                outline: none;
                border: none;
                width: 100%;
                box-sizing: border-box;
                display: block!important;
                text-align: center;
                &[type="reset"]{
                    background-color: #b5b5b5;
                }
            }
        }
    }
}
