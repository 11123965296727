.top-section-information{
    padding: 35px 0;
    @media #{$bp-s-min}{padding: 50px 0;}
    .top-headline+&{
        padding-top: 0;
    }
    // --color-highlight: var(--color-secondary);
    // color:var(--color-highlight);
    &__tab-menu,
    &__tab-content{
        max-width: 740px;
    }
    &__tab-menu{
        margin: 30px auto;
    }
    &__tab-content{
        margin: auto;
    }
    &__list{
        display: flex;
        flex-direction: column;
        gap: 1px;
        background-color: #ddd;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        margin-bottom: 30px;
    }
}
