.about{
    img{
        &[style*="float: left;"],
        &[style*="float: right;"]{
            @media #{$bp-s-max}{
                width: 150px;
            }
        }
    }
    table{
        margin: 1.5em 0 2.5em;
        th,td{
            &:not([class]),&[class=" typesquare_option"]{
                font-size: 13px;
                text-align: left;
                border-left: none;
                border-right: none;
                padding: 15px 18px;
                @media #{$bp-s-min}{
                    font-size: 16px;
                    padding: 17px 27px;
                }
            }
        }
        th:not([class]),th[class=" typesquare_option"]{
            padding: 15px 12px;
            width: 8.5em;
            @media #{$bp-s-min}{
                padding: 17px;
                width: 11.5em;
            }
        }
    }
    .incorporation-and-bylaws{
        margin-top: 50px;
        @media #{$bp-s-min}{
            margin-top: 70px;
        }
        h3:not([class]):first-child, h3[class=" typesquare_option"]{
            font-size: 1rem;
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            color: var(--color-text);
            margin-top: 2em;
            margin-bottom: .5em;
            +p{margin-top: 0;}
        }
    }
}
