.experts-detail{
    .layout__article--content3{text-align: center;}
}
.experts-detail_profile{
    &__flex{
        @media #{$bp-s-min}{
            display: flex;
            justify-content: space-between;
        }
    }
    &__info{
        text-align: center;
        flex: 1;
        @media #{$bp-s-min}{ text-align: left; }
    }
    &__position{
        line-height: 1;
        font-size: 14px;
        @media #{$bp-s-min}{ font-size: 16px; }
        [lang="ja"] &{ font-family: var(--font-ja-b); }
        font-weight: 600;
        &+&{margin-top: 6px;}
    }
    &__name{
        line-height: 1;
        font-size: 21px;
        @media #{$bp-s-min}{ font-size: 24px; }
        [lang="ja"] &{ font-family: var(--font-ja-b); }
        font-weight: 600;
        margin-top: .75em;
    }
    &__yomi{
        [lang="ja"] &{ font-family: var(--font-ja-l); }
        font-weight: 400;
        line-height: 1;
        font-size: 12px;
        @media #{$bp-s-min}{ font-size: 13px; }
        margin-top: 1em;
    }
    &__updated{
        [lang="ja"] &{ font-family: var(--font-ja-l); }
        font-weight: 400;
        line-height: 1;
        font-size: 10px;
        margin-top: 2em;
        @media #{$bp-s-min}{
            font-size: 12px;
            margin-top: 0;
        }
        text-align: right;
    }
    &__text{
        [lang="ja"] &{ font-family: var(--font-ja-l); }
        font-weight: 400;
        margin: 1em 0;
        font-size: 15px;
        @media #{$bp-s-min}{ margin: 2em 0; }
    }
    &__sns{
        display: flex;
        gap: 20px;
        &--item{
            width: 30px;
            svg{width: 100%;}
            &.tw svg{fill: #00acee;}
            &.fb svg{fill: #1877F2;}
            &.li svg{fill: #0e76a8;}
        }
    }
}
.experts-detail .article{
    [lang="ja"] &{ font-family: var(--font-ja-l); }
    font-weight: 400;
    position: relative;
    overflow: visible;
    margin-top: 50px;
    // @media #{$bp-s-min}{ margin-top: 50px; }
    &:before{
        content: "";
        display: block;
        width: 2.5em;
        height: 1px;
        background-color: var(--color-text);
        position: absolute;
        left: 0;
        top: -34px;
        // @media #{$bp-s-min}{ top: -34px; }
    }
}
