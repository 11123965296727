.about-side{
    line-height: 1.2;
    color: var(--color-primary);
    margin: 35px 0;
    @media #{$bp-s-min}{
        margin: 0;
    }
    &__item{
        &+&{
            margin-top: 10px;
        }
        &--link{
            display: block;
            padding: 12px 13px;
            border: 1px solid var(--color-primary);
            position: relative;
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            .active &{
                background-color: var(--color-primary);
                color: #fff;
                &::after{
                    border-right: 1px solid #fff;
                    border-bottom: 1px solid #fff;
                }
            }
            &::after{
                content : "";
                display: block;
                width: 5px;
                height: 5px;
                border-right: 1px solid var(--color-primary);
                border-bottom: 1px solid var(--color-primary);
                position: absolute;
                top: 50%;
                right: 13px;
                transform: translateY(-50%) rotate(-45deg);
            }
        }
    }
}
