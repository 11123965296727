.read-japan-project{
    &__title{
        text-align: center;
        margin-top: 30px;
        margin-bottom: 16px;
        @media #{$bp-s-min}{
            margin-top: 45px;
            margin-bottom: 50px;
        }
        svg{
            width: 253px;
            @media #{$bp-s-min}{
                width: 351px;
            }
        }
    }
    &__image{
        margin-top: 38px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap:10px;
        flex-direction: column;
        @media #{$bp-s-min}{
            margin-top: 52px;
            flex-direction: row;
        }
        img{
            @media #{$bp-s-min}{ width: calc(50% - 5px); }
        }
    }
    .common-section-inquiries{
        margin-top: 50px;
        @media #{$bp-s-min}{
            margin-top: 70px;
        }
    }
}
