.top-section-events{
    padding: 35px 0;
    @media #{$bp-s-min}{padding: 50px 0;}
    --color-bg: var(--color-bg-gray);
    // color: var(--color-highlight);
    &__list{
        max-width: 95%;
        margin: 30px auto 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        &.center{
            justify-content: center;
        }
        @media #{$bp-s-min}{
            gap: 20px;
        }
        @media #{$bp-l-min}{
            flex-wrap: wrap;
            flex-direction: row;
            max-width: none;
            width: 100%;
            gap: 40px;
            .entry-event{
                // &:nth-child(1),&:nth-child(2){margin: auto;}
                flex: none;
                box-sizing: border-box;
                width: calc(100% / 3 - (40px * 2 / 3));
            }
        }
        .entry-event{
            box-shadow: 6px 6px 6px rgba(0,0,0,.2);
        }
    }
}
