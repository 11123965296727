.section-title{
    text-align: center;
    font-family: var(--font-family-serif);
    font-size: 25px;
    @media #{$bp-l-min}{ font-size: 34px; }
    letter-spacing: .05em;
    line-height: 1.2;
    &.light{color: var(--color-highlight);}
    &__sub{
        [lang="ja"] &{ font-family: var(--font-ja-l); }
        font-weight: 400;
        font-size: 12px;
        @media #{$bp-l-min}{ font-size: 16px; }
        text-align: center;
        line-height: 1.2;
        margin-top: .75em;
        &.light{color: var(--color-highlight);}
    }
    &__2nd{
        font-size: 16px;
        @media #{$bp-s-min}{font-size: 20px;}
        [lang="ja"] &{ font-family: var(--font-ja-b); }
        font-weight: 600;
        border-bottom: 1px solid var(--color-secondary);
        margin: 1.25em 0 1em;
        &:first-child{margin-top: 0;}
    }
}
