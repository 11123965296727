.thumbnail-book{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    &__img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
