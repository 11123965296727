.global-header-small,
.global-header-large{
    --color-text: var(--color-highlight);
    transition: transform .4s;
    transform: translateY(0);
    .header-hidden &{transform: translateY(-101%);}
}
.global-header-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: var(--color-secondary);
    color:var(--color-text);
    width: 100%;
    height: 50px;
    @media #{$bp-s-min}{height: 70px;}
    @media #{$bp-l-min}{display: none;}
    // margin-bottom: 20px;
    position: sticky;
    top: 0;
    z-index: 2;
    &__menu{
        cursor: pointer;
        display: block;
        width: 30px;
        height: 20px;
        position: relative;
        span{
            display: block;
            height: 1px;
            width: 100%;
            background-color: var(--color-text);
            position: absolute;
            left: 0;
            &:nth-child(1){
                top:0;
                .menu-open &{
                    top: 50%;
                    transform: rotate(45deg);
                }
            }
            &:nth-child(2){
                top:50%;
                .menu-open &{
                    transform: rotateY(90deg);
                }
            }
            &:nth-child(3){
                bottom:0;
                .menu-open &{
                    bottom: 50%;
                    transform: rotate(-45deg);
                }
            }
        }
    }
    &__logo{
        width: 110px;
        @media #{$bp-s-min}{width: 158px;}
        margin: auto;
        img{width: 100%;}
    }
    &__search{
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 36px;
        height: 33px;
        line-height: 1;
        // background-color: #444;
        .sp-search-open &{
            position: relative;
            &::before,&::after{
                content:"";
                display: block;
                height: 1px;
                width: 30px;
                background-color: var(--color-text);
                position: absolute;
                top: 0;left: 0;right: 0;bottom: 0;
                margin: auto;
            }
            &::before{transform: rotate(45deg);}
            &::after{transform: rotate(-45deg);}
        }
        &--icon{
            display: block;
            text-align: center;
            svg{
                width: 53%;
                height: auto;
                fill:var(--color-text);
            }
            .sp-search-open &{display: none;}
        }
        &--text{
            display: block;
            font-size: 10px;
            line-height: 1;
            transform: scale(.8);
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            .sp-search-open &{display: none;}
        }
    }
    &__searcharea{
        position: fixed;
        top: 0;left: 0;
        z-index: 1;
        width: var(--vw);
        height: 100%;
        padding-top: 50px;
        @media #{$bp-s-min}{padding-top: 70px;}
        @media #{$bp-l-min}{display: none;}
        pointer-events: none;
        display: flex;
        flex-direction: column;
        .sp-search-open &{pointer-events: auto;}
        &--content{
            padding: 10px 20px 20px;
            background-color: var(--color-secondary);
            display: flex;
            justify-content: space-between;
            gap: 10px;
            transition: transform .4s;
            transform: translateY(-200%);
            .sp-search-open &{transform: translateY(0);}
            .freeword-search{
                display: flex;
                border: none;
                width: calc(100% - 73px - 10px);
            }
            .freeword-search--input{
                width: calc(100% - 37px);
            }
            .freeword-search--button{
                width: 37px;
                height: 37px;
            }
        }
        &--button{
            display: block;
            height: 37px;
            width: 73px;
            line-height: 37px;
            font-size: 10px;
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            text-align: center;
            padding: 0 10px;
            background-color: var(--color-highlight);
            color: var(--color-secondary);
            [lang="en"] &{
                line-height: 1.2;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
.global-header-large{
    display: flex;
    @media #{$bp-l-max}{display: none;}
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: var(--color-secondary);
    color:var(--color-text);
    width: 100%;
    height: 100px;
    position: sticky;
    top: 0;
    z-index: 2;
    &__wrapper{
        width: 100%;
        height: 100%;
        max-width: $page-width-wide-max;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__logo{
        width: 268px;
    }
    &__menu{
        flex: 1;
        line-height: 1;
    }
    &__sublist{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 14px;
        font-size: 12px;
        [lang="ja"] &{ font-family: var(--font-ja-b); }
        font-weight: 600;
        letter-spacing: .1em;
        [lang="en"] &{ letter-spacing: 0.02em; }
        &--item{
            margin-left: 2em;
        }
        &--link{
            opacity: .8;
            &:hover{
                text-decoration: underline;
                text-decoration-thickness: 1px;
            }
        }
        i{
            display: inline-block;
            width: 1em;
            svg{
                width: 11px;
                fill: var(--color-text);
            }
        }
    }
    &__lang{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &--item{
            text-align: center;
            &+&{
                margin-left: .25em;
                &::before{
                    content:"/";
                    position: relative;
                    top: -2px;
                    margin-right: .25em;
                    opacity: .7;
                }
            }
        }
        &--link{
            opacity: .7;
            font-size: 14px;
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            [lang="ja"] &.ja{ opacity: 1; pointer-events: none; }
            [lang="en"] &.en{ opacity: 1; pointer-events: none; }
            &:hover{
                text-decoration: underline;
                text-decoration-thickness: 1px;
            }
        }
    }
    &__mainlist{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        letter-spacing: .075em;
        [lang="en"] &{ letter-spacing: 0; }
        gap: 0 1em;
        font-size: 14px;
        @media(min-width: 1140px){
            gap: 0 1.5em;
            font-size: 16px;
        }
        @media(min-width: 1220px){
            gap: 0 2.25em;
            [lang="en"] &{gap: 0 2em;}
        }
        &--item{
            white-space: nowrap;
            flex:0;
            position: relative;
        }
        &--link{
            &:hover{
                text-decoration: underline;
                text-decoration-thickness: 1px;

            }
            &[href^="#mega"]{
                position: relative;
                padding-right: 15px;
                &::after{
                    content : "";
                    display: block;
                    width: 5px;
                    height: 5px;
                    border-right: 1px solid var(--color-text);
                    border-bottom: 1px solid var(--color-text);
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%) rotate(45deg);
                }
            }
            .megamenu-open[data-megamenu='#mega-menu1'] &[href="#mega-menu1"],
            .megamenu-open[data-megamenu='#mega-menu2'] &[href="#mega-menu2"],
            .megamenu-open[data-megamenu='#mega-menu3'] &[href="#mega-menu3"]{
                opacity: .8;
                &::after{transform: translateY(-40%) rotate(-135deg);}
            }
        }
    }
    &__search{
        transition: width .4s;
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 100%;
        background-color:var(--color-secondary);
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        z-index: 1;
        .search &{
            width: 65vw;
            @media(min-width: 1140px){width: 70vw;}
            @media(min-width: 1220px){width: 880px;}
        }
        .freeword-search{
            flex: 1;
            border: none;
            &--button{
                width: 28px;
                height: 28px;
            }
        }
        &--advanced{
            display: flex;
            background-color: var(--color-highlight);
            color:var(--color-secondary);
            font-size: 12px;
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: 600;
            line-height: 1;
            padding: 0 15px;
            align-items: center;
            justify-content: center;
            &:hover{
                opacity: .9;
            }
        }
        &--close{
            cursor: pointer;
            width: 28px;
            height: 28px;
            outline: none;
            border: none;
            appearance: none;
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            background-color:var(--color-secondary);
            position: relative;
            &::before,&::after{
                content:"";
                display: block;
                height: 1px;
                width: 30px;
                background-color: var(--color-highlight);
                position: absolute;
                top: 0;left: 0;right: 0;bottom: 0;
                margin: auto;
            }
            &::before{transform: rotate(45deg);}
            &::after{transform: rotate(-45deg);}
        }
        &--button{
            position: relative;
            z-index: 0;
            margin-left: 1em;
            [lang="en"] &{margin-left: 0em;}
            color:var(--color-secondary);
            font-size: 14px;
            cursor: pointer;
            border: none;
            border-radius: 0;
            outline: none;
            appearance: none;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            height: 28px;
            background: var(--color-text);
            [lang="ja"] &{ font-family: var(--font-ja-b); }
            font-weight: bold;
            padding: 0 10px;
            i{
                margin-left: 5px;
            }
            svg{
                fill:var(--color-secondary);
                width: 16px;
            }
            // &:hover{
            //     opacity: .9;
            // }
        }
    }
}
