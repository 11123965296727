$page-width-wide-max: 1200px;
$page-width-medium-max: 1076px;
$page-width-narrow-max: 964px;
$page-width-minimum-max: 750px;
:root{
    --font-family-serif: "Times New Roman", serif;
    --color-darkest: #202020;
    --color-highlight: #fff;
    --color-primary: #962238;
    --color-secondary: #03043f;
    --color-bg-gray: #ebebeb;
    --color-gray-text: #505050;
    --color-text: var(--color-darkest);
    --color-bg: var(--color-highlight);
    --font-ja-l: "Shuei KakuGo Gin L", dnp-shuei-gothic-gin-std, sans-serif;
    --font-ja-m: "Shuei KakuGo Gin M", dnp-shuei-gothic-gin-std, sans-serif;
    --font-ja-b: "Shuei KakuGo Gin B", dnp-shuei-gothic-gin-std, sans-serif;
}
[lang="ja"] body{
    // --font-family: dnp-shuei-gothic-gin-std, sans-serif; //adobe
    --font-family: var(--font-ja-m); //モリサワ
    font-weight: 500;
}
[lang="en"] body{
    --font-family: "Open Sans", open-sans, sans-serif; //google
    font-weight: 500;
}

// デバイス定義
// スマホ：600px以下
// タブレット：601px以上 - 1024px以下
// PC：1025px以上
$bp-s-min:"(min-width:600px)";
$bp-s-max:"(max-width:599px)";
$bp-l-min:"(min-width:1025px)";
$bp-l-max:"(max-width:1024px)";
// Ex.
// body{
//     background-color: red;
//     @media #{$bp-s-min}{background-color: blue;}
//     @media #{$bp-l-min}{background-color: pink;}
// }
// デバイスではなく要素都合のクエリは直接記述
// コンテナクエリ使用のために下記ポリフィルを導入
// https://github.com/GoogleChromeLabs/container-query-polyfill

%bg-fill{
    position: relative;
    margin: 0 auto;
    &::after{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: calc( -1 * (var(--vw) - 100%) / 2 );
        width: var(--vw);
        height: 100%;
        background-color: var(--color-bg);
        z-index: -1;
    }
}
