.sylff-organization{
    &__title{
        text-align: center;
        margin-top: 30px;
        margin-bottom: 16px;
        @media #{$bp-s-min}{
            margin-top: 45px;
            margin-bottom: 50px;
        }
        svg{
            width: 230px;
            @media #{$bp-s-min}{
                width: 296px;
            }
        }
    }
    &__lead{
        text-align: center;
        line-height: 1.53;
        margin-bottom: 23px;
        @media #{$bp-s-min}{
            margin-bottom: 35px;
        }
    }
    &__chart{
        margin-bottom: 1.5em;
        &--caption{
            line-height: 1.27;
            font-size: 11px;
            color: #808080;
            @media #{$bp-s-min}{
                line-height: 1.7;
                font-size: 10px;
            }
        }
    }
    &__table{
        margin-top: 22px;
        border-collapse: collapse;
        line-height: 1.6;
        text-align: left;
        @media #{$bp-s-min}{
            margin-top: 30px;
        }
        th,td{
            border-top: 1px solid #b5b5b5;
            border-bottom: 1px solid #b5b5b5;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 10px;
            @media #{$bp-s-min}{
                padding-left: 18px;
            }
        }
        th{
            font-weight: bold;
            background-color: var(--color-bg-gray);
            width: 9em;
        }
        td{
            ul{
                padding-left: 0;
                li{
                    list-style: none!important;
                }
            }
        }
    }
    .common-section-inquiries{
        margin-top: 50px;
        @media #{$bp-s-min}{
            margin-top: 70px;
        }
    }
}
