.experts-inquiries{
    background-color: var(--color-secondary);
    padding: 30px 20px;
    &__title{
        color:var(--color-highlight);
        text-align: center;
        line-height: 1.6;
        font-size: 16px;
        @media #{$bp-s-min}{ font-size: 18px; }
        @media #{$bp-l-min}{ br{display: none;} }
        margin-bottom: 30px;
    }
    .button__fill{
        max-width: 400px;
        margin: auto;
    }
}
