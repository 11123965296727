.publications{
    &__list{
        margin: 28px 0;
        @media #{$bp-s-min}{
            margin: 45px 0;
        }
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        // justify-content: space-between;
        gap: 30px 20px;
        .entry-book{ width: calc( 100% / 2 - (20px * (2 - 1) / 2) ); }
        @media #{$bp-s-min}{
            gap: 50px 30px;
            .entry-book{ width: calc( 100% / 4 - (30px * (4 - 1) / 4) ); }
        }
        @media #{$bp-l-min}{
            gap: 60px 40px;
            .entry-book{ width: calc( 100% / 5 - (40px * (5 - 1) / 5) ); }
        }
        .entry-book__text--title{
            font-size: 12px;
            @media #{$bp-s-min}{font-size: 15px;}
            margin-bottom: .5em;
        }
        .entry-book__text--author{
            font-size: 11px;
            @media #{$bp-s-min}{font-size: 13px;}
        }
        .entry-book__text--info{
            font-size: 10px;
            @media #{$bp-s-min}{font-size: 12px;}
        }
    }
}
