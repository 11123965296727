.freeword-search{
    display: flex;
    color: var(--color-text);
    border: 1px solid var(--color-secondary);
    // margin: 30px auto;
    // @media #{$bp-s-min}{ margin: 50px auto; }
    &--input,
    &--button{
        display: block;
        border: none;
        border-radius: 0;
        outline: none;
        appearance: none;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        background-color: var(--color-highlight);
    }
    &--input{
        flex: 1;
        padding-left: 10px;
        color: var(--color-darkest);
        font-weight: 400;
        &::-webkit-clear-button,
        &::-webkit-clear-button,
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button{display: none; appearance: none;}
        appearance: none;
        &:-webkit-autofill{
            box-shadow: 0 0 0px 1000px #fff inset;
        }
    }
    &--button{
        cursor: pointer;
        width: 50px;
        height: 50px;
        // @media #{$bp-s-min}{
        //     width: 60px;
        //     height: 60px;
        // }
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
            fill:var(--color-secondary);
            width: 18px;
        }
    }
}
