.common-section-publications{
    padding: 35px 20px;
    @media #{$bp-s-min}{padding: 50px 20px;}
    --color-bg: var(--color-bg-gray);
    &__slider{
        margin-top: 30px;
        margin-bottom: 30px;
        @media#{$bp-l-max}{
            margin-left: -20px;
            margin-right: -20px;
        }
        // @media(min-width: $page-width-wide-max){
        //     margin-left: calc( -1 * (100vw - 100%) / 2 );
        //     margin-right: calc( -1 * (100vw - 100%) / 2 );
        // }
    }
    &__list{
        max-width: 860px;
        margin: auto;
        margin-top: 30px;
        margin-bottom: 30px;
        @media#{$bp-l-max}{
            // margin-left: -20px;
            // margin-right: -20px;
        }
        display: flex;
        justify-content: center;
        &+&{ justify-content: flex-start; }
        gap: 20px;
        flex-wrap: wrap;
        @media #{$bp-l-min}{
            flex-wrap: nowrap;
        }
        .entry-book{
            // width: calc(100% / 2 - 10px);
            // @media #{$bp-s-min}{width: calc(100% / 3 - (40px / 3));}
            @media #{$bp-s-max}{
                flex-direction: row;
                align-items: flex-start;
                gap: 20px;
                .entry-book__thumbnail{
                    width: 110px;
                }
                .entry-book__text{
                    flex: 1;
                    margin-top: 0;
                }
            }
            @media #{$bp-s-min}{
                width: calc(100% / 4 - (80px / 4));
                // &:last-child{display: none;}
            }
        }
    }
}
