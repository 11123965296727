.global-breadcrumb{
    display: block;
    line-height: 1.4;
    padding:0 20px;
    margin: 15px 0;
    font-size: 10px;
    @media #{$bp-s-min}{
        margin: 20px 0;
        font-size: 11px;
    }
    // overflow-x: auto;
    // -ms-overflow-style: none;
    // scrollbar-width: none;
    // &::-webkit-scrollbar{ display: none; }
    &__list{
        width: 100%;
        max-width: $page-width-wide-max;
        margin: auto;
        // display: flex;
        // justify-content: flex-start;
        // align-items: center;
        // gap: 10px 3em;
        &--item{
            display: inline;
            position:relative;
            // white-space: nowrap;
            // &:last-child{ padding-right: 20px; }
            &+&::before{
                content: "　>　";
                // position: absolute;
                // top: 0;
                // left: -1.75em;
            }
        }
        &--link{
            color:var(--color-primary);
            &:hover{
                text-decoration: underline;
                text-decoration-thickness: 1px;
            }
        }
    }
}
