.thumbnail{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    &__img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 1s;
    }
    .top-headline__1st &,
    .top-headline__2nd &{
        &::before{
            content:"";
            display: block;
            position: absolute;
            top: 0;left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 75%, rgba(0,0,0,0) 100%);
            z-index: 1;
        }
    }
    &.movie::after{
        content:"";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7.25px 0 7.25px 12.5px;
        @media #{$bp-s-min}{
            border-width: 14.5px 0 14.5px 25px;
        }
        border-color: transparent transparent transparent #fff;
        position: absolute;
        top: 0;left: 0;right: 0;bottom: 0;
        margin: auto;
        filter: drop-shadow(0 0 5px rgba(0,0,0,.5));
    }
}
