.entry-book{
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 0;
    [lang="ja"] &{ font-family: var(--font-ja-l); }
    font-weight: 400;
    &__permalink{
        // pointer-events: none;
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;left: 0;
        z-index: 1;
        overflow: hidden;
        text-indent: -9999px;
        &:hover{
            ~.entry-book__text{
                .entry-book__text--title{
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                }
            }
        }
    }
    &__thumbnail{
        width: auto;
        height: 160px;
        position: relative;
    }
    &__text{
        margin-top: 12px;
        line-height: 1.5;
        font-size: 12px;
        &--title{
            font-weight: 500;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            @media #{$bp-s-min}{ font-size: 13px; }
        }
        &--author{
            margin-top: .25em;
            color:var(--color-primary);
            &--item{
                [lang="ja"] &:last-child::after{
                    content:" 著";
                    color: var(--color-text);
                }
                [lang="en"] &::before{
                    content:"By";
                    color: var(--color-text);
                    margin-right: .5em;
                }
            }
            &--link{
                position: relative;
                z-index: 2;
                &:hover{
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                }
            }
        }
        &--info{
            position: relative;
            margin-top: 1em;
            padding-top: 1em;
            font-size: 10px;
            &::before{
                content:"";
                display: block;
                width: 3em;
                height: 1px;
                background-color: #aaa;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        &--publisher{margin-bottom: .25em;}
        &--date{}
    }
}
