[lang="en"]{
    .ml__main,
    .terms,
    .privacy,
    .sitemap,
    .csr,
    .leadership,
    .layout__about--main,
    .article .entry-expert__other--contents,
    .experts-detail_profile__text,
    .article .entry-expert__other--title{
        font-size: 15px;
        line-height: 1.8;
        @media #{$bp-s-min}{
            font-size: 18px;
            line-height: 1.72;
        }
    }
}
.article{
    display: block;
    overflow: hidden;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    sup{
        font-size: 0.7em;
        vertical-align:super;
    }
    sub{
        font-size: 0.7em;
        vertical-align:bottom;
    }
    [lang="ja"] & *{

        box-sizing: inherit;
    }
    @media #{$bp-s-max}{
        width: calc( 100vw - 40px );
    }
    font-size: 15px;
    @media #{$bp-s-min}{ font-size: 16px; }
    [lang="en"] &{
        font-size: 15px;
        line-height: 1.8;
        @media #{$bp-s-min}{
            font-size: 18px;
            line-height: 1.72;
        }
    }
    .fill-button{
        display: block;
        background-color: var(--color-secondary);
        color: var(--color-highlight);
        [lang="ja"] &{ font-family: var(--font-ja-b); }
        font-weight: 600;
        line-height: 1;
        text-align: center;
        padding: 15px;
        width: 70%;
        max-width: 375px;
        margin: auto;
        @media #{$bp-l-min}{ margin-left: 0; }
        &:hover{ opacity: .9; }
    }
    &__id{
        font-size: 13px;
        text-align: right;
        color: var(--color-gray-text);
        &+h2,&+h3,&+h4,&+h5,&+h6{
            margin-top: 0;
        }
    }
    .reset-text{
        font-size: 1rem;
    }
    figure{
        display: block;
        img{display: block;}
        figcaption{
            margin-top: 1em;
            color: #808080;
            line-height: 1.27;
            font-size: 11px;
            @media #{$bp-s-min}{
                font-size: 10px;
                line-height: 1.7;
            }
        }
        max-width: 100%;
        @media #{$bp-s-min}{
            &.left,&.right{
                width: 41.5%;
                margin-top: .5em;
                margin-bottom: .25em;
            }
            &.left{
                float: left;
                margin-right: 1em;
            }
            &.right{
                float: right;
                margin-left: 1em;
            }
        }
    }
}
.indent,
blockquote{
    padding-left: 2em;
    padding-right: 2em;
}
br.pc{
    @media #{$bp-s-max}{ display: none; }
}
br.sp{
    @media #{$bp-s-min}{ display: none; }
}
h2:not([class]),
h2[class=" typesquare_option"],
.headLine2{
    [lang="ja"] &{ font-family: var(--font-ja-l); }
    font-weight: 500;
    letter-spacing: .1em;
    display: block;
    font-size: 19px;
    margin-top: 2em;
    margin-bottom: -.5rem;
    padding: .25em 0 0;
    @media #{$bp-s-min}{font-size: 28px;}
    color: var(--color-secondary);
    border-top: 1px solid var(--color-secondary);
    line-height: 1.6;
    font-feature-settings:"palt";
    [lang="en"] &{
        padding-top: .75em;
        padding-bottom: .5em;
        letter-spacing: 0;
        line-height: 1.42;
    }
    strong{
        [lang="ja"] &{ font-family: var(--font-ja-l); }
        font-weight: 500;
    }
    &:first-child{margin-top: auto;}
    &:last-child{margin-bottom: auto;}
    .about &{
        margin-bottom: -.5rem;
    }
}
h3:not([class]),
h3[class=" typesquare_option"],
.headLine3{
    [lang="ja"] &{ font-family: var(--font-ja-b); }
    font-weight: 500;
    letter-spacing: .1em;
    margin: 1.5em 0 1em;
    font-size: 18px;
    @media #{$bp-s-min}{font-size: 24px;}
    color: var(--color-secondary);
    line-height: 1.6;
    font-feature-settings:"palt";
    [lang="en"] &{letter-spacing: 0;}
    &:first-child{margin-top: auto;}
    &:last-child{margin-bottom: auto;}
    strong{
        [lang="ja"] &{ font-family: var(--font-ja-l); }
        font-weight: 400;
    }
}
h4:not([class]),
h4[class=" typesquare_option"],
.headLine4{
    [lang="ja"] &{ font-family: var(--font-ja-b); }
    font-weight: 600;
    margin: 1.5em 0 1em;
    font-size: 16px;
    @media #{$bp-s-min}{font-size: 20px;}
    color: var(--color-secondary);
    line-height: 1.6;
    font-feature-settings:"palt";
    &:first-child{margin-top: auto;}
    &:last-child{margin-bottom: auto;}
}
h5:not([class]),
h5[class=" typesquare_option"],
.headLine5{
    [lang="ja"] &{ font-family: var(--font-ja-b); }
    font-weight: 600;
    margin: 1.5em 0 1em;
    font-size: 16px;
    @media #{$bp-s-min}{font-size: 20px;}
    line-height: 1.6;
    font-feature-settings:"palt";
    &:first-child{margin-top: auto;}
    &:last-child{margin-bottom: auto;}
}
h6:not([class]),
h6[class=" typesquare_option"],
.headLine6{
    [lang="ja"] &{ font-family: var(--font-ja-l); }
    font-weight: 400;
    margin: 1.5em 0 1em;
    font-size: 16px;
    @media #{$bp-s-min}{font-size: 20px;}
    line-height: 1.6;
    font-feature-settings:"palt";
    &:first-child{margin-top: auto;}
    &:last-child{margin-bottom: auto;}
}

strong:not([class]),strong[class=" typesquare_option"],.strong{
    [lang="ja"] &{ font-family: var(--font-ja-b); }
    font-weight: 600;
}
em:not([class]),em[class=" typesquare_option"]{
    font-style: italic;
    // color: var(--color-gray-text);
}
ul:not([class]),ul[class=" typesquare_option"]{
    padding-left: 1.5em;
    li:not([class]){list-style: disc;}
}
ol:not([class]),ol[class=" typesquare_option"]{
    padding-left: 1.5em;
    li:not([class]){list-style: decimal; padding-left: .5em; }
}
li:not([class]),li[class=" typesquare_option"]{
    line-height: 1.6;
    margin: .5em 0;
}
img{
    vertical-align: bottom;
    max-width:100%;
    height: auto;
    &[style*="float: left;"],
    &[style*="float: right;"]{
        max-width:60%;
    }
    &[style*="float: left;"]{margin-right: 1em;}
    &[style*="float: right;"]{margin-left: 1em;}
}
p,ul,ol,table,figure{
    &:not([class]),&[class=" typesquare_option"]{
        margin: 1.25em 0;
        [lang="en"] &{margin: .75em 0;}
        &:first-child{margin-top: auto;}
        &:last-child{margin-bottom: auto;}
    }
}
a[class]{
    text-decoration: none;
}
a:not([class]),a[class=" typesquare_option"]{
    color:var(--color-primary);
    text-decoration: underline;
}

hr:not([class]),hr[class=" typesquare_option"]{
    border: none;
    display: block;
    height: 1px;
    background-color: #d5d5d5;
    margin: 2.5em auto;
}
table{
    border-collapse: collapse;
    margin: 1.25em 0;
    [lang="en"] &{margin: .75em 0;}
    box-sizing: border-box;
    th,td{
        padding: .5em;
        border: 1px solid #ccc;
    }
}
table:not([class]),table[class=" typesquare_option"]{
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    font-size: .8em;
    @media #{$bp-s-min}{font-size: .9em;}
    line-height: 1.4;
    th:not([class]),th[class=" typesquare_option"],td:not([class]),td[class=" typesquare_option"]{
        width: auto;
        max-width: 100%;
    }
    th:not([class]),th[class=" typesquare_option"]{
        [lang="ja"] &{ font-family: var(--font-ja-b); }
        font-weight: 600;
        background-color: var(--color-bg-gray);
    }
}
