.entry{
    container: inline-size / entry-wrapper;
    position: relative;
    page-break-inside: avoid;
    break-inside: avoid;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 0;
    border-top: 1px solid var(--color-text);
    padding: 20px 0;
    @container entry-wrapper size(max-width: 220px){
        padding: 10px 0;
    }
    &:last-child{padding-bottom: 0;}
    .top-headline__1st &{
        padding: 0;
        border-top: none;
        flex-direction: column;
        color: var(--color-highlight);
        width: 100%;
        aspect-ratio: 16 / 9;
    }
    .top-headline__2nd &{
        padding: 0;
        border-top: none;
        flex-direction: column;
        width: calc(50% - 4px);
        @media #{$bp-s-min}{
            width: calc(50% - 2px);
            aspect-ratio: 16 / 9;
            color: var(--color-highlight);
        }
        @media #{$bp-l-min}{
            width: 100%;
            height: calc(50% - 2px);
        }
    }
    &__permalink{
        // pointer-events: none;
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;left: 0;
        z-index: 1;
        overflow: hidden;
        text-indent: -9999px;
        &:hover{
            ~.entry__thumbnail .thumbnail__img{
                transform: scale(1.05);
            }
            ~.entry__text{
                .entry__text--title{
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                }
            }
        }
    }
    &__thumbnail{
        width: 100%;
        max-width: 100px;
        aspect-ratio: 16 / 9;
        position: relative;
        margin-left: 1em;
        @media #{$bp-s-min}{
            margin-left: 2em;
        }
        line-height: 1;
        color: var(--color-highlight);
        @container entry-wrapper size(min-width: 460px){
            max-width: 190px;
        }
        .top-headline__1st &{
            max-width: none;
            position: absolute;
            top: 0;left: 0;
            width: 100%;
            height: 100%;
            margin-left: 0;
        }
        .top-headline__2nd &{
            max-width: none;
            width: 100%;
            margin-left: 0;
            @media #{$bp-s-min}{
                position: absolute;
                top: 0;left: 0;
                height: 100%;
            }
        }
        &--ribbon{
            max-width: 90%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: var(--color-primary);
            font-size: 10px;
            @media #{$bp-l-min}{ font-size: 12px; }
            .top-headline__1st &,
            .top-headline__2nd &{
                font-size: 12px;
                @media #{$bp-l-min}{ font-size: 15px; }
            }
            &--link{
                max-width: 100%;
                display: block;
                padding: 4px 5px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                // position: relative;
                // z-index: 2;
                // &:hover{ background-color: #333; }
            }
        }
        &--caption{
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 5px;
            display: none;
            font-size: 10px;
            [lang="ja"] &{ font-family: var(--font-ja-l); }
            font-weight: 400;
            .top-headline__1st &{display: block;}
            .top-headline__2nd &{
                @media #{$bp-s-min}{ display: block; }
            }
        }
    }
    &__text{
        flex: 1;
        line-height: 1.5;
        .top-headline__1st &{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 0 10px 10px;
            &.no-caption{
                padding: 0 10px 5px;
                @media #{$bp-s-min}{ padding: 0 10px 10px; }
            }
        }
        .top-headline__2nd &{
            margin-top: 10px;
            @media #{$bp-s-min}{
                margin-top: 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 0 10px 18px;
                &.no-caption{padding: 0 10px 10px;}
            }
        }
        &--title{
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 13px;
            line-height: 1.5;
            [lang="ja"] &{ font-family: var(--font-ja-l); }
            font-weight: 500;
            .top-headline__1st &{ font-size: 17px; }
            .top-headline__2nd &{ font-size: 14px; }
            @media #{$bp-l-min}{
                font-size: 16px;
                .top-headline__1st &{ font-size: 26px; }
                .top-headline__2nd &,
                .top-headline__3rd &,
                .top-headline__4th &{ font-size: 18px; }
            }
            @container entry-wrapper size(max-width: 220px){
                font-size: 14px;
            }
            .search__list &{
                font-size: 13px;
                @media #{$bp-s-min}{font-size: 18px;}
            }
        }
        &--flex{
            display: flex;
            margin-top: .5em;
            font-size: 10px;
            @media #{$bp-s-min}{ font-size: 12px; }
            @container entry-wrapper size(max-width: 220px){
                display: block;
                font-size: 10px;
            }
        }
        &--date{
            color: var(--color-gray-text);
            padding-right: 1em;
            [lang="ja"] &{ font-family: var(--font-ja-l); }
            font-weight: 400;
            @container entry-wrapper size(max-width: 220px){
                display: block;
                margin-bottom: 4px;
            }
        }
        &--author{
            flex: 1;
            color:var(--color-primary);
            display: inline-flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            [lang="ja"] &{ font-family: var(--font-ja-l); }
            font-weight: 400;
            .top-headline__1st &{color: var(--color-highlight);}
            .top-headline__2nd &{
                @media #{$bp-s-min}{
                    color: var(--color-highlight);
                }
            }
            &--item{
                &::after{content:" ,";margin-right: .5em;}
                &:last-child::after{content:"";}
            }
            &--link{
                position: relative;
                // z-index: 2;
                // &:hover{
                //     text-decoration: underline;
                //     text-decoration-thickness: 1px;
                // }
            }
        }
        &--tags{
            color:var(--color-primary);
            font-size: 10px;
            margin-top: .5em;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            @media #{$bp-s-min}{ font-size: 12px; }
            .top-headline &{display: none;}
            &--item{
                margin-top: 4px;
                margin-right:4px;
            }
            &--link{
                position: relative;
                z-index: 2;
                display: block;
                line-height: 1;
                background-color: var(--color-highlight);
                border: 1px solid rgba(#962238,.8);
                border-radius: 30px;
                padding: 4px 7px;
                @media #{$bp-s-min}{ padding: 5px 10px; }
                &:hover{
                    background-color: #fafafa;
                }
            }
        }
    }
}
