.research-result{
    &__title{
        [lang="ja"] &{ font-family: var(--font-ja-l); }
        font-weight: 400;
        line-height: 1.6;
        text-align: center;
        font-size: 19px;
        margin-bottom: 28px;
        @media #{$bp-s-min}{
            margin-bottom: 45px;
            font-size: 30px;
            br{display: none;}
        }
    }
    &__list{
        margin: 28px 0;
        @media #{$bp-s-min}{
            margin: 45px 0;
        }
        @media #{$bp-l-min}{
            display: flex;
            flex-wrap: wrap;
            gap:0 30px;
            .entry{width: calc(100% / 2 - 15px);}
        }
        .entry__text--tags{display: none;}
    }
}
